.fp-carousel {
  @include size(max-content, null, $maxw: 100%);
  @include margins(0 auto);
  @include flex(space-between, center, null);

  &__prev-arrow,
  &__next-arrow {
    @include size(36px, 36px);
    @include transition(0.2s, ease);
    border-radius: 100%;
    cursor: pointer;

    &:hover {
      background: $light-gray;
    }
  }


  &__dots {
    @include flex(center, center, null);
    @include margins(0 $spacer-h-m);

    .slick-dots {
      @include flex(center, null, null);
      position: static;

      li {
        @include flex(center, center, null);
        @include margins(0);


        &:last-child {
          @include margins(0);
        }

        button {
          @include size(12px, 12px);
          @include padding(0);
          background: $light-gray;
          border: 1px solid $dark-gray;
          border-radius: 100%;
          outline: none;

          &::before {
            content: '';
            display: none;
          }
        }
      }

      .slick-active {
        button {
          background: $color-primary;
        }
      }
    }

    &.slick-hidden {
      display: none;
    }

    &-gray {
      color: $dark-gray;
    }

    &-igi-gat-test-test {
      display: none;
    }
  }

  &__white {
    fill: $white;
  }

  &__dark-gray {
    fill: $dark-gray;
  }
}
