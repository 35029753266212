@import './modifiers/paywall_overlay';

@mixin fp-paywall-layer-flyin-content {
  ul {
    flex: 1;

    li {
      @include flex();
      @include margins(0 0 $spacer-v-xxxs 0);

      &:last-child {
        @include margins(0);
      }

      span {
        @include size(18px, auto);
        @include margins(7px $spacer-h-xxs 0 0);
      }

      p {
        @include font($text-l, $text-regular, normal, $lh: 1.775rem);
        flex: 1;
      }
    }
  }
}

.fp-article-paywall {
  &--no-leading-paragraph {
    .fp-article-paywall__overlay {
      display: none;
    }
  }

  &__login-text {
    color: #ba0000;

    &:hover {
      text-decoration: underline;
    }
  }

  .fp-paywall-layer-bubble {
    @include margins(20px auto 0);
    @include padding(20px);
  }

  &__overlay {
    background: linear-gradient(0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 250, 0) 100%);
    height: 150px;
    margin-top: -150px;
    position: relative;
    z-index: 1;
  }

  &__dialog {
    @include size(100%, auto);
    @include flex(null, null, column);
    @include margins($spacer-v-xl auto $spacer-v-xxxl);
    @include padding(0 $spacer-h-xs);
    background: $light-gray;

    &__paid {
      background: #304fa8;
    }
  }

  &__header {
    @include flex(center, center, column);
    @include margins(0 0 $spacer-v-m 0);
    @include padding(0 $spacer-h-xs);
    text-align: center;
  }

  &__logo {
    @include size($paywall-logo-width, 80px);
    @include margins($spacer-v-xxxl auto $spacer-v-xs);
    background-image: url($paywall-logo-flyin);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;

    &--plus {
      @include size(165px, $paywall-logo-height);
      background-image: url($paywall-plus-logo);
    }
  }

  &__title {
    @include font($text-l, $text-regular, normal, $lh: 1.775rem);
    color: $text-color;

    &--plus {
      @include font($text-xl, $text-bold, normal, $lh: $lh-xl);
    }
  }

  &__body {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: $spacer-v-xs;
    grid-template-columns: 1fr;
  }

  &__plan {
    @include padding($spacer-v-l $spacer-h-xs);
    background: $white;
    display: grid;
    grid-auto-rows: auto;
    grid-gap: $spacer-v-xs;

    &--title {
      h3,
      h4 {
        @include font($text-xxl, $text-bold, normal, $lh: 2.539rem);
        color: $text-color;
      }

      h4 {
        @include margins(0.2rem 0 0 0);
        font-size: $text-xl;
      }
    }

    &--list {
      flex: 1;
    }

    &--body {
      @include size(100%, null);
      @include flex(null, center, null);

      ul {
        flex: 1;
        list-style-image: url('/design/source/images/paywall-checkmark.svg');

        li {
          @include flex();
          @include margins(0 0 $spacer-v-xxxs 0);

          &:last-child {
            @include margins(0);
          }

          span {
            @include size(18px, auto);
            @include margins(7px $spacer-h-xxs 0 0);
          }

          p {
            @include font($text-l, $text-regular, normal, $lh: 1.775rem);
            flex: 1;
          }
        }
      }

      figure {
        @include size(244px, null, $maxw: 244px);

        @media (max-width: $tablet) {
          display: none;
        }

        img {
          @include size(100%, 100%);
          display: block;
        }

        amp-img {
          @include size(244px, 184px);
        }
      }
    }

    &--footer {
      @include flex(space-between, center, null);

      @media (max-width: $tablet) {
        align-items: flex-start;
        flex-direction: column;
      }

      .fp-button {
        @include size(244px, null);
        @include flex(center, center, null);
        @include margins(0);
        display: inline-flex;
        text-transform: none;

        @media (max-width: $tablet) {
          width: 100%;
        }

        svg {
          @include size(10px, 20px);
          @include margins(0 0 0 4px);
        }
      }
    }

    &--price {
      @media (max-width: $tablet) {
        @include margins(0 0 $spacer-v-xxxs 0);
      }

      h5 {
        @include font($text-xl, $text-bold, normal, $lh: 2.1rem);
        color: $color-primary;
      }

      p {
        @include font($text-l, $text-regular, normal, $lh: 1.775rem);
        color: $text-color;
      }
    }
  }

  &__footer {
    @include margins($spacer-v-xl 0);
    @include flex(center, center, null);
    @include font($text-l, $text-regular, normal, $lh: 1.775rem);
    color: $text-color;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    a {
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.fp-paywall-layer-new {
  margin-left: -10%;
  width: 121%;

  &__gallery {
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: $mobile) {
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
  }

  .fp-article-paywall {
    &__dialog {
      background: linear-gradient($pw-background, $pw-background) no-repeat bottom;
      background-size: auto calc(100% - 205px);
      border-bottom: 1px solid #c6c1ba;
      position: relative;

      @media (max-width: $mobile) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }
    }

    &__header {
      @include flex(center, center, row);
      @include padding(40px 0 20px 0);

      @media (max-width: $tablet) {
        padding-bottom: 10px;
      }

      &__gallery {
        @include padding(0);
      }

      h3 {
        @media (max-width: $tablet) {
          font-size: 1.4rem;
        }

        @media (max-width: $mobile) {
          font-size: 1.25rem;
        }
      }
    }

    &__logo {
      @include margins(0 $spacer-v-xs 0);

      @media (max-width: $tablet) {
        height: 31px;
        max-width: 98px;
      }

      @media (max-width: $mobile) {
        height: 24px;
        max-width: 75px;
      }
    }

    &__body {
      @include flex(center, inherit, row);
      grid-gap: 20px;
      margin-bottom: 50px;
      margin-left: auto;
      margin-right: auto;
      max-width: 810px;
      width: 100%;

      &--wider {
        max-width: 910px;
      }

      @media (max-width: $tablet) {
        grid-gap: 12px;
      }

      @media (max-width: $tablet) {
        grid-gap: 5px;
      }
    }

    &__wrapper {
      background-color: $white;
      border-top-left-radius: 15px;
      box-shadow: 0 0 18px #3232324d;
      max-width: 650px;
      width: 100%;
    }

    &__plan {
      align-content: baseline;
      border: 3px solid transparent;
      border-top-left-radius: 15px;
      height: 100%;
      position: relative;

      &--title {
        text-align: center;

        p {
          font-size: 1.25rem;
          margin-bottom: 0.4rem;

          @media (max-width: $tablet) {
            font-size: 1rem;
            margin-bottom: 0;
          }

          @media (max-width: $mobile) {
            font-size: 0.75rem;
          }
        }

        h3 {
          font-size: 2.4rem;
          margin-bottom: 1rem;

          @media (max-width: $tablet) {
            font-size: 1.9rem;
            margin-bottom: 0;
          }

          @media (max-width: $mobile) {
            font-size: 1.25rem;
            line-height: 1;
            margin-top: 8px;
          }
        }
      }

      .fp-button {
        @include margins(0 auto);
        letter-spacing: 0;
        max-width: 145px;
        text-transform: none;
        width: 100%;

        span {
          color: $white;
        }

        @media (max-width: $tablet) {
          font-size: 1rem;
        }

        @media (max-width: $mobile) {
          font-size: 0.75rem;
          max-width: 80%;
        }
      }

      &--footer {
        display: block;
      }

      &--price {
        text-align: center;

        h5 {
          color: $text-color;
          font-size: 1.25rem;

          @media (max-width: $tablet) {
            font-size: 1rem;
            line-height: 1.333;
          }

          @media (max-width: $mobile) {
            font-size: 0.75rem;
            margin-bottom: 6px;
          }
        }

        p {
          font-size: 1.25rem;
          margin-bottom: 1.5rem;

          @media (max-width: $tablet) {
            font-size: 1rem;
            line-height: 1.333;
          }

          @media (max-width: $mobile) {
            font-size: 0.75rem;
            margin-bottom: 10px;
          }
        }
      }

      &--description {
        color: $text-color;
        font-size: 1.25rem;
        margin-bottom: 0.9rem;
        text-align: center;

        @media (max-width: $tablet) {
          font-size: 1rem;
          margin-bottom: 0;
        }

        @media (max-width: $mobile) {
          font-size: 0.75rem;
        }
      }

      &--best-deal {
        border-color: $special-color-1;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        position: relative;
      }

      &__best-deal-wrapper {
        background-color: $special-color-1;
        bottom: -49px;
        display: none;
        left: 0;
        padding: 6px;
        position: absolute;
        width: 100%;

        p {
          color: $white;
          font-size: 1.56rem;
          font-weight: bold;
          text-align: center;

          @media (max-width: $tablet) {
            font-size: 1.25rem;
          }

          @media (max-width: $mobile) {
            font-size: 0.8rem;
          }
        }

        @media (max-width: $tablet) {
          bottom: -42px;
        }

        @media (max-width: $mobile) {
          bottom: -28px;
        }
      }

      @media (max-width: $tablet) {
        border-top-left-radius: 11px;
        max-width: 495px;
        min-height: inherit;
        padding: 1.5rem 0.2rem;
      }

      @media (max-width: $mobile) {
        border-top-left-radius: 8px;
        border-width: 2px;
        padding: 0.8rem 0.2rem;
      }
    }

    &__footer {
      @media (max-width: $tablet) {
        font-size: 1.125rem;
      }

      @media (max-width: $mobile) {
        font-size: 1rem;
        line-height: 1.3;
        margin: 0.5rem 0 1.5rem;
      }
    }
  }
}

.fp-paywall-layer-new .fp-article-paywall__plan--best-deal>.fp-article-paywall__plan {
  border-color: $special-color-1;
}

.fp-article-paywall__plan--best-deal .fp-article-paywall__plan__best-deal-wrapper {
  display: block;
}

.fp-paywall-layer-new.doc-component {
  margin-left: 0;
  width: 100%;
}

.fp-paywall-layer-flyin-wrapper {
  @include padding(0);

  @media (min-width: $mobile + 1) {
    @include padding(0 5px);
  }

  &.fp-paywall-layer-flyin_no-img {
    figure {
      display: none;
    }

    .fp-paywall-layer-flyin--content {
      flex: 3;
    }
  }
}

.fp-paywall-layer-flyin-light {
  position: relative;

  &--toggle {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
  }

  &--header-container {
    @include flex(space-between, center);
    margin: 0 auto;
    max-width: 970px;
    min-height: 46px;
    position: relative;
  }

  &--header-container::after {
    border-bottom: 2px dotted $light-gray;
    bottom: -10px;
    content: '';
    left: 50%;
    max-width: 970px;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }

  &--button {
    background: linear-gradient(180deg, white 50%, transparent 50%);
    border: 1px solid black;
    border-radius: 50%;
    border-width: 1px 0 0;
    display: inline-block;
    height: 50px;
    margin-top: -1rem;
    width: 50px;
    z-index: 1;

    svg {
      left: 50%;
      position: relative;
      stroke: black;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  &--logo-and-title,

  &--action-buttons {
    @include flex(center, center, row, nowrap);
  }

  &--action-buttons {
    gap: 16px;
  }
}

.fp-paywall-layer-flyin {
  @include margins(0 auto);
  background-color: white;
  border: 1px solid #c6c1ba;
  max-width: 970px;
  z-index: 10000000000;

  &--plus {
    background-image: url($paywall-logo-flyin);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: inline-block;
    height: 32px;
    margin-right: 25px;
    width: 32px;
  }

  &--header {
    border-radius: 7px 7px 0 0;
    padding: 0.5rem;
  }

  &--contentbar {
    @include flex();
    padding: 1rem;
  }

  &--contentbar-light {
    @include flex();
    padding: 1rem 1rem 0;
  }

  &--image {
    flex: 1;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }

  &--image-light {
    aspect-ratio: 16 / 9;
    flex: 1;
    margin-right: 20px;
    max-height: 80px;
    min-height: 64px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  &--list {
    @include margins(20px 0 0);
  }

  &--content {
    @include fp-paywall-layer-flyin-content;
    flex: 2;
  }

  &--content-light {
    @include fp-paywall-layer-flyin-content;
    flex: 4;
  }

  &--action {
    @include flex(center, center);
    flex: 1;

    a {
      @include margins(0);
      text-transform: none;
    }

    svg {
      height: 20px;
      margin: 0 0 0 4px;
      padding-top: 5px;
      width: 10px;
    }
  }

  &--header-container {
    @include flex();
    margin: 0 auto;
    max-width: 970px;
    position: relative;
  }

  &--header-container::after {
    border-bottom: 2px dotted $light-gray;
    bottom: -10px;
    content: '';
    left: 50%;
    max-width: 970px;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
  }

  &--closebutton {
    cursor: pointer;
    margin-left: auto;

    svg {
      fill: #c6c1ba;
    }
  }

  &--title {
    @include font($text-l, $text-bold, normal, $lh: 2.1rem);
    display: inline-block;
  }

  &--body {
    @include font($text-l, $text-regular, normal, $lh: 2.1rem);
  }
}

.fp-paywall-layer-overlay_plus-logo .fp-paywall-layer-flyin--plus {
  background-image: url($paywall-plus-logo);
  width: 60px;
}

.fp-paywall-layer-breakingbar-wrapper {
  background-color: $color-primary;
  color: white;
}

.fp-paywall-layer-breakingbar {
  @include margins(0 auto);
  @include flex(space-between);
  @include padding(10px 20px);
  max-width: 1200px;
  z-index: 10000000000;

  &--closebutton {
    cursor: pointer;

    svg {
      fill: black;
      opacity: 0.3;
    }
  }

  &--body {
    @include font($text-l, $text-regular, normal, $lh: 2.1rem);
    color: white;
  }

  &--action {
    margin-left: auto;
    margin-right: 60px;

    a {
      @include font($text-l, $text-regular, normal, $lh: 2.1rem);
      color: white;
      cursor: pointer;
      text-decoration: none;
    }

    .action-image {
      background-image: url('/design/source/images/right-arrow.svg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: inline-block;
      height: 16px;
      width: 10px;
    }
  }
}

.fp-paywall-layer-bubble {
  @include flex(space-between);
  @include padding(20px);
  -webkit-box-shadow: 10px 10px 20px $dark-gray;
  -moz-box-shadow: 10px 10px 20px $dark-gray;
  box-shadow: 10px 10px 20px $dark-gray;
  max-width: 500px;
  position: relative;

  &--body {
    @include font($text-m, $text-regular, normal, $lh: 1.4rem);
  }

  &--closebutton {
    cursor: pointer;
    margin-left: 30px;
  }

  &--tooltip {
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: 10px;
    height: 0;
    position: absolute;
    right: 0;
    right: 10px;
    top: -20px;
    transform: rotate(90deg);
    width: 0;
  }
}

@media (max-width: 785px) {
  .fp-paywall-layer-breakingbar {
    @include flex(space-between, null, column);
    @include padding(10px 10px 20px);
    @include margins(0 40px 0 0);

    &--action {
      margin-left: unset;
      margin-right: unset;
      margin-top: 20px;

      a {
        @include font($text-m, $text-regular, normal, $lh: 1.4rem);
      }

      .action-image {
        height: 11px;
        width: 8px;
      }
    }

    &--body {
      @include font($text-m, $text-regular, normal, $lh: 1.4rem);
    }

    &--closebutton {
      position: absolute;
      right: 10px;
    }
  }

  .fp-paywall-layer-flyin-wrapper {
    figure {
      display: none;
    }
  }

  .fp-paywall-layer-flyin {
    &--title {
      @include font($text-m, $text-bold, normal, $lh: 1.4rem);
    }

    &--contentbar {
      @include flex(center, center, column);
      text-align: center;
    }

    &--list {
      @include margins(20px 0 0);
      text-align: left;
    }

    &--content {
      ul {
        li {
          span {
            @include size(13px, auto);
            @include margins(0 $spacer-h-xxs 0 0);
          }

          p {
            @include font($text-m, $text-regular, normal, $lh: 1.4rem);
          }
        }
      }
    }

    &--action {
      a {
        @include margins(10px 0 0);
        @include font($text-m, $text-bold, normal, $lh: 1.4rem);
      }
    }

    &--plus {
      align-self: center;
      margin-right: 10px;
      min-width: 32px;
    }

    &--body {
      @include font($text-m, $text-regular, normal, $lh: 1.4rem);
    }
  }
}
