@import './modifiers/authors-page-components';

.fp-authors-heading {
  &--personal-info {
    @include flex(flex-start, center, row, nowrap);
    background-color: #f7f6f4;
    gap: 96px;
    height: 182px;
    position: relative;
  }

  &--image {
    height: 100%;
    margin-left: 80px;

    img {
      aspect-ratio: 1/1;
      border-radius: 50%;
      height: 100%;
      position: relative;
      top: -16px;
    }
  }

  &--contact-and-link {
    flex: 1;
  }

  &--focus-and-description {
    flex: 1.75;
  }

  @media(max-width: $tablet) {
    &--personal-info {
      flex-wrap: wrap;
      gap: unset;
      height: unset;
      justify-content: center;
      min-height: 182px;
    }
    
    &--name-and-department {
      flex-basis: 100%;
      margin-bottom: 24px;

      .fp-authors-personal-info--full-name {
        justify-content: center;
        margin-bottom: 8px;
      }
      
      .fp-authors-personal-info--department {
        text-align: center;
      }
    }
    
    &--image {
      height: 182px;
      margin-left: unset;
    }

    &--focus-and-description {
      flex-basis: 100%;
      margin-top: 3rem;
    }
  }
}

.fp-authors-additional-info {
  display: flex;
  flex-wrap: wrap;

  &--contact-icons {
    display: flex;
    gap: 24px;
  }

  &--link {
    div {
      border-bottom: 1px solid black;
      display: inline-block;
      
      a {
        color: black;
      }
    }
  }
  
  &--focus-items {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: flex-start;
  }

  &--focus-item {
    border: 1px solid #d4cfca;
    border-radius: 4px;
  }
}

.fp-authors-top-articles,

.fp-authors-further-articles {
  h1 {
    overflow: hidden;
    text-align: center;

    &::before,
    &::after {
      background-color: #000;
      content: '';
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    &::before {
      margin-left: -50%;
      right: 0.5em;
    }

    &::after {
      left: 0.5em;
      margin-right: -50%;
    }
  }
}

.fp-authors-further-articles-grid-row {
  display: grid;
  // Insert grid-gap style
  grid-template-columns: repeat(12, 1fr);
  width: 100%;

  @media(max-width: $tablet) {
    grid-auto-rows: auto;
    grid-gap: 0;
    grid-template-columns: 1fr;
  }
}

.fp-authors-further-articles-three-teasers {
  margin-bottom: 3rem;
  @media(max-width: $tablet) {
    margin-bottom: 2rem;
  }

  &.fp-authors-border-l {
    padding-bottom: 1.25rem;
    // Insert border-bottom style
  }

  .fp-col-4 {
    @media(max-width: $tablet) {
      grid-column: span 1;
    }
  }

  &__single {
    .fp-col-4 {
      @media(max-width: $tablet) {
        border-bottom: 0;
        grid-column: span 1;
        margin: 0;
        padding: 0;
      }
    }
  }
}

.fp-authors-further-articles-three-teasers {
  ld-include {
    grid-column: 1 / -1;
  }
  
  .embed {
    &.dynamic-vertical-teasers {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);

      .placeholder {
        article {
          figure {
            width: 100%;
          }
        }
      }
    }
  }

  .fp-listing-teaser-link-box__container,
  .fp-listing-teaser-single__container {
    .embed {
      &.dynamic-vertical-teasers {
        display: block;
      }
    }
  }
}
