.fp-article {
  .fp-ad-rectangle {
    &__1 {
      @media(min-width: $mobile + 1) {
        @include size(null, null, $minh: $rectangle-h);
        @include margins($spacer-v-s);
        clear: right;
        float: right;
      }
    }

    &__1 {
      @include margins(0 auto $spacer-v-m auto);
    }

    &__2 {
      @include margins(0 auto $spacer-v-m auto);
      clear: both;
    }
  }

  @media(min-width: $mobile + 1) {
    .fp-ad-halfpage {
      @include size(null, null, $minh: $halfpage-h, $minw: $halfpage-w);
      @include margins($spacer-v-s);
      clear: right;
      float: right;
      margin-right: 0;
      margin-top: 0;
    }
  }
}

.fp-ad-rectangle {
  @include margins(0 auto $spacer-v-m auto);
  @include size(100%, null, $minh: $rectangle-h, $maxw: $rectangle-w);
  @include flex(center, center, null);

  // these were provided to us by ids, and we'll ignore the lint for this case
  /* stylelint-disable selector-max-id, selector-no-qualifying-type */
  [data-ad-position*='rectangle'],
  div#westfalen-blatt\.de_mr_1,
  div#westfalen-blatt\.de_mr_2,
  div#westfalen-blatt\.de_mr_3 {
    @include size(100%, 100%);
  }
  /* stylelint-enable selector-max-id, selector-no-qualifying-type */

  &__placeholder {
    @include margins(0 auto);
    @include size(100%, 100%, $minh: $rectangle-h);
    @include flex(center, center, null);

    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $ad-yellow;
    color: $white;
    max-width: 300px;
  }
}
