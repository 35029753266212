.fp-editor-container {
  .fp-gallery {
    .gallery-image {
      .fp-article__image {
        @include padding(0 0 0 0);
      }
    }

    // hide the gallery ad in the editor
    .gallery-ad {
      display: none;
    }
  }
}

.fp-gallery {
  &:hover {
    .fp-carousel__prev-arrow,
    .fp-carousel__next-arrow {
      background: transparent;
      opacity: 1;
    }
  }

  .fp-gallery-carousel__controlls {
    @include size(100%, initial);
    background: transparent;

    > a {
      @include font($text-l, $text-regular, normal, $lh: 24px);
      @include flex(null, center, null);
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }

      &:first-child {
        svg {
          @include margins(0 $spacer-h-xxxs 0 0);
        }
      }

      &:last-child {
        svg {
          @include margins(0 0 0 $spacer-h-xxxs);
        }
      }

      svg {
        @include size(auto, 6px);

        path {
          fill: $color-primary;
        }
      }
    }

    &__progress {
      @include font($text-l, $text-regular, normal, $lh: 24px);
      color: $text-color;
    }
  }

  .fp-gallery-carousel__container {
    position: relative;

    ul {
      @include size(100%, auto);
    }
  }


  &.fp-gallery-carousel {
    .slick-arrow {
      @include size(50px, 50px);
      background: rgba($black, 0.2);
      position: absolute;
      top: calc(730px / 2);
      transform: translateY(-50%);
      z-index: 100;

      svg {
        path {
          fill: $white;
        }
      }

      &.fp-carousel__prev-arrow {
        left: 0;
      }

      &.fp-carousel__next-arrow {
        right: 0;
      }
    }
  }

  .fp-carousel__prev-arrow,
  .fp-carousel__next-arrow {
    @include transition(0.2s, ease);
    opacity: 0;
  }

  .gallery-image {
    .fp-article__image {
      @include size(100%, auto, $maxw: 100%, $maxh: 730px);
      @include padding(0 0 56.25% 0);
      background-color: transparent;
      overflow: hidden;
      position: relative;

      img {
        @include size(100%, auto, $maxw: 100%, $maxh: 730px);
        object-fit: contain;
      }
    }
  }

  .fp-gallery__info {
    @include padding($spacer-v-xs 10px 10px 10px);
    background: $light-gray;
    border-bottom: 2px solid $text-color;

    p {
      font-size: $text-l;
    }

    &--metadata {
      color: #878787;
    }

    &--source {
      @include margins(3px 0 0 0);
      color: $dark-gray;
    }
  }

  &__before-progress {
    visibility: hidden;
    width: 134px;
  }

  .gallery-ad {
    background-color: $white;
    height: 100%;
    opacity: 0.95;
    position: absolute;
    text-align: center;
    top: 0;
    transition: visibility 3s;
    width: inherit;

    &__close {
      cursor: pointer;
      pointer-events: auto;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    &__hidden {
      display: none;
    }

    .gtm-ad-slot {
      margin-top: 80px;

      iframe {
        pointer-events: auto;
      }
    }
  }

  &__disabled {
    pointer-events: none;
  }
}

@media(max-width: $mobile) {
  .fp-gallery__before-progress {
    display: none;
  }
}
