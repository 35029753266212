.fp-paid-article {
  &__article,
  &__section-title {
    &::before {
      @include size(3.3125rem, 1.0625rem, $minw: 3.3125rem);
      @include margins(0 1.5rem 0 0);
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      position: relative;
      top: -18%;
    }
  }
}

.fp-paid-article {
  &__article {
    &::before {
      background-image: url($paid-article-logo);
    }
  }

  &__section-title {
    &::before {
      background-image: url($paid-article-logo);
    }
  }
}

.fp-paid-article-indicator-r {
  &__article {
    &::after {
      @include space(margin, left, $spacer * 4);
      background-image: url($paid-article-logo);
    }
  }
}
