.fp-social-media-container {
  @include flex(null, center, null);
  @include margins(0 0 $spacer-v-xxxl 0);

  &__button {
    @include size(80px, 36px);
    @include margins(0 $spacer-h-xs 0 0);
    @include flex(center, center, null);
    @include padding(0.2rem 0);
    @include transition(0.15s, ease);

    &:last-child {
      @include margins(0);
    }

    span {
      @include size(24px, 24px);
    }

    svg {
      @include size(100%, 100%);

      path {
        fill: $white;
      }
    }
  }

  .fp-facebook {
    background: $facebook;
  }

  .fp-twitter {
    background: $twitter;
  }

  .fp-print {
    background: $print;
  }

  .fp-mail {
    background: $mail;
  }

  .fp-facebook,
  .fp-twitter,
  .fp-print,
  .fp-mail {
    &:hover {
      background: $text-color;
    }
  }
}
