.fp-ad-nativendo-one-third {
  @include size(null, 100%);

  [data-ad-position='nativendo-one-third'] {
    @include size(100%, 100%);
  }

  &__placeholder {
    @include size(100%, 100%);
    @include flex(center, center, null);

    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $ad-yellow;
    color: $white;
  }
}
