.fp-article-teaser {
  @media(max-width: $tablet) {
    @include flex();
    @include padding(0 0 $spacer-v-s 0);
    @include margins(0 0 $spacer-v-s 0);
    border-bottom: $border-dotted;
  }

  @media(max-width: $mobile) {
    flex-direction: column;
  }

  &__image {
    @include size(100%, 0);
    @include padding(0 0 66.66% 0);
    @include margins(0 0 $spacer-v-s 0);
    display: block;
    overflow: hidden;
    position: relative;
    @media(max-width: $tablet) {
      @include size(15.125rem, null);
      @include padding(0 0 22.22% 0);
      @include margins(0 $spacer-h-xs 0 0);
    }

    @media(max-width: $mobile) {
      @include size(100%, null);
      @include padding(0 0 60% 0);
      @include margins(0 0 $spacer-v-s 0);
    }

    img {
      @include size(100%, 100%);
      object-fit: cover;
      position: absolute;
    }
  }

  header {
    @include margins(0 0 $spacer-v-xxxs 0);
  }

  &__content {
    @include padding(0 $spacer-h-xxs);
    border-left: $border-dotted;
    @media(max-width: $tablet) {
      @include padding(0);
      border: 0;
      flex: 1;
    }
    @media(max-width: $mobile) {
      @include size(100%, null);
      @include padding(0 $spacer-h-xxs);
    }
  }

  &__city--vertical {
    @include margins(0 0 $spacer-h-xxs 0);
    color: $dark-gray;
    display: inline-block;
    font-weight: bold;
  }

  &__city {
    @include margins(0 $spacer-v-xxxs 0 0);
    color: $dark-gray;
    font-weight: bold;
    word-break: break-all;
  }

  &__catchline {
    @include font($text-m, $text-bold, normal);
    @include margins(0 0 $spacer-h-xxs 0);
    color: $text-color;

    @media (max-width: $tablet) {
      font-size: $text-m;
      line-height: $lh-m;
    }
  }

  &__title {
    @include font($text-l, $text-bold, normal, $lh: $lh-l);
    @include margins(0 0 $spacer-v-xxs 0);

    &:hover {
      color: $color-primary;
    }
  }

  &__details {
    @include flex(null, center, null);
  }

  &__author,
  &__date {
    @include font($text-s, $text-regular, normal);
    color: $dark-gray;
  }

  &__author {
    @include padding(0 $spacer-h-xxxs 0 0);
  }

  &__date {
    @include flex(null, null, row, wrap);
    @include padding(0 0 0 $spacer-h-xxxs);
    border-left: 1px solid $dark-gray;
  }
}
