.fp-ad-billboard {
  @include margins($spacer-v-m auto $spacer-v-m auto);

  [data-ad-position='billboard'] {
    @include size(100%, $billboard-h, $maxw: $billboard-w);
    @include flex(center, center, null);

    @media(max-width: $tablet) {
      @include size(100%, null, $maxw: $billboard-w-t);
    }

    @media(max-width: $mobile) {
      @include size(100%, null, $maxw: $billboard-w-m);
    }
  }

  // these were provided to us by ids, and we'll ignore the lint for this case
  /* stylelint-disable selector-max-id, selector-no-qualifying-type */
  [data-ad-position='billboard1'],
  [data-ad-position='billboard2'],
  [data-ad-position='billboard3'],
  div#westfalen-blatt\.de_bb_1,
  div#westfalen-blatt\.de_bb_2,
  div#westfalen-blatt\.de_bb_3 {
    @media(max-width: $mobile) {
      @include margins($spacer-v-m auto $spacer-v-m auto);
    }
  }
  /* stylelint-enable selector-max-id, selector-no-qualifying-type */

  &__placeholder {
    @include size(100%, $billboard-h, $maxw: $billboard-w);
    @include flex(center, center, null);

    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $ad-yellow;
    color: $white;
  }
}
