// This file contains all the EXPANDABLE IMAGE styles
// This file is imported into the _article.scss file


.fp-article {
  .fp-infobox {
    .fp-figure {
      height: 0;
      padding-bottom: 66.6666666667%;
    }

    .fp-figure__expandable--toggle {
      @media(max-width: $mobile) {
        display: flex;
      }
    }
  }

  .fp-figure__expandable {
    height: 0;
    padding-bottom: $expandable-image-padding;
    @media(max-width: $tablet) {
      padding-bottom: $expandable-image-padding-tablet;
    }

    @media(max-width: $mobile) {
      padding-bottom: 66.6666666667%;
    }

    img {
      @include size(100%, auto);
      object-position: top;
      position: absolute;
    }

    &--toggle {
      @include size(2.6rem, 2.6rem);
      @include flex(center, center, null);
      @include transition(0.2s, ease);
      background: $black;
      border-radius: 100%;
      bottom: $spacer-v-xs;
      cursor: pointer;
      left: $spacer-h-xs;
      opacity: 0.7;
      position: absolute;
      @media(max-width: $mobile) {
        display: none;
      }

      svg {
        @include transition(0.2s, ease);
      }

      &:hover {
        background: $white;

        svg {
          path {
            fill: $black;
          }
        }
      }
    }
  }
}

