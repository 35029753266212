.fp-single-teaser__big {
  .fp-single-teaser__container {
    .fp-teaser-big {
      padding: 35px;

      &__header {
        font-size: 26px;
        font-weight: 900;
        padding-bottom: 20px;
      }

      &__content {
        font-size: 22px;
        padding-bottom: 20px;
      }

      &__link-container {
        align-items: center;
        display: flex;
    
        a {
          color: #cb0000;
          font-size: 24px;
          font-weight: bold;
          text-decoration: underline;
        }
      }
    }
  }
}
