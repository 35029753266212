.fp-ad-anker {
  @include margins($spacer-v-m auto $spacer-v-m auto);

  [data-ad-position='anker'] {
    @include size(100%, $anker-h, $maxw: $anker-w);
    @include flex(center, center, null);
    @include margins(0 auto);
  }

  &__placeholder {
    @include size(100%, 90px);
    @include flex(center, center, null);

    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $ad-yellow;
    color: $white;
  }
}
