.fp-multimedia {
  padding-top: $spacer-v-xl;

  &-container {
    @include margins(0 0 $spacer-v-xxxl 0);
    background: $light-gray;
  }

  .fp-grid-row {
    @include size(auto, null);
    @media(max-width: $tablet) {
      grid-auto-rows: auto;
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }

  &-row {
    &__first,
    &__third {
      @include flex(center, null, null);
    }

    &__second {
      @include padding(0 $spacer-h-s $spacer-v-xl $spacer-h-s);

      .fp-article-teaser {
        @media(max-width: $mobile) {
          border-bottom: 0;
        }
      }
    }
  }

  &__title {
    @include size(max-content, null);
    @include font($text-xl, $text-bold, normal, $lh: $lh-xl);
    @include margins(0 auto);
    @include padding($spacer-v-xs $spacer-h-m 0 $spacer-h-m);
    background: $light-gray;
    color: $dark-gray;
    letter-spacing: 0.05rem;
    position: relative;
    text-align: center;
    top: -$spacer-v-xl;
    @media(max-width: $mobile) {
      @include size(90%, null);
      @include font($text-l, $text-bold, normal, $lh: $lh-l);
      @include padding($spacer-v-xxxs $spacer-h-xs 0 $spacer-h-xs);
      box-sizing: content-box;
      top: -$spacer-v-m;
    }
  }

  &__logo-container {
    @include padding($spacer-v-xs $spacer-h-m 0 $spacer-h-m);
    @include margins(0 auto);
    background: $white;
    display: none;
    position: relative;
    top: -2.5rem;
  }

  &__logo {
    @include size(10.3125rem, 3.3125rem);
  }

  .fp-col-4 {
    @include margins(0 $spacer-h-s 0 0);
    grid-column: span 4;

    &:last-child {
      @include margins(0);
    }

    @media(max-width: $tablet) {
      @include size(100%, null);
      grid-column: span 1;
    }
  }

  &-row {
    &__second {
      @media(max-width: $mobile) {
        @include padding(0);
      }

      .slick-slide {
        > div {
          @include flex(flex-start, null, null);
          @media(max-width: $tablet) {
            flex-direction: column;
          }
        }
      }
    }

    &__third {
      .fp-carousel {
        @include size(max-content, null);
        @include padding(0 0 $spacer-v-s 0);

        &__white {
          fill: transparent;
        }
      }
    }
  }

  .fp-multimedia-style {
    &--marketing {
      background: $special-color-2;

      .fp-multimedia__title {
        background: $special-color-2;
      }
    }

    &--plus {
      background: $white;
      border: $border-solid;

      .fp-multimedia__logo-container {
        display: block;
      }

      .fp-multimedia__logo {
        background-image: url($paid-article-logo);
        background-position: center center;
        background-repeat: no-repeat;
      }

      .fp-multimedia__title {
        display: none;
      }
    }

    &--special {
      background: $light-gray;

      .fp-multimedia__title {
        background: $light-gray;
      }
    }

    &--sonderthemen {
      background: $white;
      border: $border-solid;

      .fp-multimedia__title {
        background: $white;
      }
    }
  }

  .fp-multimedia__title a {
    color: $color-primary;
  }
}

.fp-multimedia-container.text-color-white {
  .fp-multimedia__title {
    color: $white;
  }

  .fp-article-teaser__content header {
    span,
    p,
    h2 {
      color: $white;
    }
  }

  .fp-article-teaser__content footer {
    p,
    time {
      color: $white;
    }
  }
}

.doc-section.fp-editor-container {
  .fp-multimedia-new {
    .fp-multimedia-row__second section {
      grid-column: span 4;
    }

    .embed.dynamic-vertical-teasers .placeholder {
      flex: 1;
    }
  }
}

.fp-multimedia-new {
  @include margins(0 0 $spacer-v-xxxl);

  .fp-single-teaser.fp-single-teaser__small {
    margin: 0 1.25rem 0 0;

    &:last-child {
      margin: 0;
    }
  }
}
