// This file contains all the BREAKING NEWS styles
// This file is imported into the _components.scss file


// Translation animation

@keyframes breaking-news {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

.fp-breaking-news {
  @include margins($spacer * 8 auto);
  @include size(100%, $spacer * 9);
  @include flex();

  &:hover {
    .fp-breaking-news__news-reel {
      animation-play-state: paused;
    }
  }

  @media(max-width: $mobile) {
    @include size(null, auto);
    @include margins($spacer * 4 0 0 0);
    flex-direction: column;
  }

  &__title {
    @include padding(0 $spacer * 6);
    @include flex(center, center, null);
    background: $color-primary;

    h4 {
      color: $white;
      font-size: $text-s;
      font-weight: $text-bold;
      text-transform: uppercase;
      white-space: nowrap;
    }

    @media(max-width: $mobile) {
      @include padding(calc($spacer * 2) $spacer * 6);
    }
  }

  &__news-container {
    @include size(100%, null);
    @include padding(0 $spacer * 4);
    @include flex(null, center, null);
    background: red;
    flex: 1;
    overflow: hidden;

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    @media(max-width: $mobile) {
      @include padding(calc($spacer * 2) $spacer * 4);
    }
  }

  &__news-reel {
    @include flex();
    animation: breaking-news;
    animation-duration: 30s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    flex: 1;
  }

  &__item {
    @include padding(0 $spacer * 6 0 0);

    h1 {
      color: $white;
      font-size: $text-m;
      font-weight: $text-bold;
      white-space: nowrap;

      &::after {
        @include size(calc($spacer * 2), calc($spacer * 2));
        @include margins(0 0 0 calc($spacer * 2));
        background: $white;
        border-radius: 100%;
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
