
.fp-infobox {
  @include size(100%, null);
  @include font($text-m, $text-regular, normal, $lh: $lh-m);
  @include margins(0 0 $spacer-v-m 0);
  @include flex();
  background: $light-gray;
  color: $text-color;

  @media (max-width: $mobile) {
    flex-direction: column;
  }

  &.fp-infobox--text-only {
    figure {
      display: none;
    }
  }

  &__left {
    flex: 1;

    &--title {
      @include padding($spacer-v-xs $spacer-h-xs $spacer-v-s);
      font-size: 20px;
      line-height: calc(20px * 1.5);
    }
  }

  &__right {
    @include flex(null, null, column);
    flex: 3;
  }

  &__content {
    @include padding($spacer-v-s);

    figure {
      @include margins(0 0 $spacer-v-m 0);
    }
  }

  &__text-content {
    @include transition(0.5s, ease-in-out);
    overflow: hidden;

    a {
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__toggle {
    @include size(30px, 30px);
    @include margins(0 $spacer-v-s $spacer-v-s 0);
    align-self: flex-end;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    svg {
      left: 0;
      position: absolute;
      top: 0;

      path {
        fill: $text-color;
      }
    }
  }
}
