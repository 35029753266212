.fp-grid-two-columns {
  @include margins(0 0 $spacer-v-xxxl);
  @media(max-width: $tablet) {
    @include margins(0 0 $spacer-v-xl 0);
  }

  &.fp-grid-row {
    @media(max-width: $tablet) {
      grid-template-columns: 1fr;
    }
  }

  .fp-col-1,
  .fp-col-2,
  .fp-col-3,
  .fp-col-4,
  .fp-col-5,
  .fp-col-6,
  .fp-col-7,
  .fp-col-8,
  .fp-col-9,
  .fp-col-10,
  .fp-col-11,
  .fp-col-12 {
    @media(max-width: $tablet) {
      grid-column: 1 / -1;
    }
  }
}


.fp-grid-two-columns {
  &.fp-grid-row {
    @media(max-width: $tablet) {
      @include flex(null, null, column);
    }
  }
}
