// This file contains all the TOP ARTICLE TEASERS styles
// This file is imported into the _groups.scss file

.fp-top-article-top-teaser {
  .fp-article__content {
    @include padding(0 50px 8px 50px);
    @media (max-width: 750px) {
      @include padding(0 0 8px 0);
    }

    .fp-article__title {
      font-size: $text-xxl;
      @media (max-width: 750px) {
        font-size: $text-m;
      }
    }
  }
}

.fp-top-article-bottom-teasers {
  &.fp-grid-row {
    grid-gap: $spacer * 6;
  }
  @media (max-width: 750px) {
    > div {
      grid-column: span 12;
    }
  }

  .fp-article__title {
    font-size: $text-m;
  }
}
