.fp-listing-teaser-link-box {
  border-bottom: $border-l;
  margin-bottom: $spacer-v-xl;

  .fp-cta-link__title {
    border-bottom: 0;

    &:not([href]) {
      cursor: auto;
    }

    &:hover {
      border-bottom-color: unset;
    }
  }

  &__container {
    @include size(100%, null);
    margin-bottom: $spacer-v-s;
  }

  &__article {
    @media(max-width: $tablet) {
      @include flex();
    }

    @media(max-width: $mobile) {
      flex-direction: column;
    }
  }

  &__header {
    @include margins(0 0 $spacer-v-xs 0);
  }

  &__image {
    @include size(100%, 0);
    @include padding(0 0 66.66% 0);
    @include margins(0 0 $spacer-v-xs 0);
    display: block;
    overflow: hidden;
    position: relative;
    @media(max-width: $tablet) {
      @include size(15.125rem, null);
      @include margins(0 $spacer-h-xs 0 0);
    }

    @media(max-width: $mobile) {
      @include size(100%, null);
      @include padding(0 0 60% 0);
      @include margins(0 0 $spacer-v-s 0);
    }

    img {
      @include size(100%, 100%);
      object-fit: cover;
      position: absolute;
    }
  }

  &__content {
    @include margins(0 0 $spacer-v-xxs 0);
    border-bottom: $border-dotted;
    @media(max-width: $tablet) {
      @include padding(0);
      flex: 1;
    }
    @media(max-width: $mobile) {
      @include size(100%, null);
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__article-list {
    @include padding(0 0 0 $spacer-h-xxs);
    border-left: $border-dotted;
    @media(max-width: $tablet) {
      @include padding(0);
      border: 0;
    }
  }

  &__catchline {
    @include font($text-m, $text-bold, normal);
    @include margins(0 0 $spacer-h-xxs 0);
    color: $text-color;

    @media (max-width: $tablet) {
      font-size: $text-m;
      line-height: $lh-m;
    }
  }

  &__title {
    @include font($text-l, $text-bold, normal, $lh: $lh-l);
    @include margins(0 0 $spacer-v-xxs 0);

    @media (max-width: $mobile) {
      @include font($text-l, null, null);
    }

    &:hover {
      color: $color-primary;
    }
  }

  &__date {
    @include font($text-s, $text-regular, normal);
    @include margins(0 0 $spacer-v-s 0);
    color: $dark-gray;
  }
}
