// This file includes all the ARTICLE LISTS styles
// It is imported in the article.scss file

.fp-article-view-list {
  @include padding(0 0 $spacer-v-m 1.3rem);

  ::marker {
    color: $text-color;
  }

  &__unordered {
    list-style-type: disc;
  }

  &__ordered {
    list-style-type: decimal;

    ::marker {
      font-weight: $text-regular;
    }
  }
}

.fp-list-item {
  @include font($text-l, $text-regular, normal, $lh: $lh-l);

  a {
    color: $color-primary;
    
    &:hover {
      text-decoration: underline;
    }
  }
}
