.fp-ad-halfpage {
  @include margins(0 auto $spacer-v-m auto);
  @include size($halfpage-w, null, $minw: $halfpage-w, $minh: $halfpage-h, $maxh: $halfpage-h);
  @include flex(center, center, null);
  @media(max-width: $tablet) {
    @include size(null, $halfpage-h);
  }
  @media(max-width: $mobile) {
    @include size(null, $halfpage-h-m);
  }

  [data-ad-position='halfpage'] {
    @include size(100%, 100%);
  }

  &__placeholder {
    @include margins(0 auto);
    @include size(100%, $halfpage-h, $maxw: $halfpage-w);
    @include flex(center, center, null);

    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $ad-yellow;
    color: $white;
  }
}
