// This file contains all the LINKS styles
// This file is imported into the _components.scss file

.fp-link {
  &__primary {
    @include font($text-xl, $text-bold, null, $lh: $lh-xl);
    @include margins(0 $spacer-h-s $spacer-v-xl 0);
    color: $color-primary;
    cursor: pointer;
    display: inline-block;
  }

  &__secondary {
    @include font($text-l, null, null, $lh: $lh-l);
    @include margins(0 $spacer-h-s $spacer-v-xl 0);
    border-bottom: 0.0625rem solid $text-color;
    color: $text-color;
    cursor: pointer;
    display: inline-block;

    &:hover {
      border-bottom-color: $color-primary;
      color: $color-primary;
    }
  }

  &__text {
    @include font($text-xl, null, null, $lh: $lh-xl);
    @include margins(0 $spacer-h-s $spacer-v-xl 0);
    color: $text-color;

    &:hover {
      color: $color-primary;
    }
  }
}
