.fp-ad-nativendo-two-thirds {
  &__placeholder {
    @include size(100%, 300px);
    @include flex(center, center, null);

    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $ad-yellow;
    color: $white;
  }
}
