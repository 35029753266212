
// This file contains all the GALLERY LIST styles
// This file is imported into the _components.scss file

.fp-gallery-list {
  &__main-teaser {
    @include margins(0 0 $spacer * 8 0);

    .fp-article__image {
      overflow: initial;
    }
  }

  &__teaser {
    @include margins(0 0 $spacer * 8 0);

    &:last-child {
      @include margins(0);
    }

    a {
      @include flex();
      @media (max-width: $tablet) {
        @include flex(null, null, column);
      }
    }

    .fp-article__image {
      @include size(100%, 225px, $maxw: 400px);
      @include space(margin, right, $spacer * 6);
      @include space(margin, bottom, 0);

      @media (max-width: $tablet) {
        @include size(100%, auto, $maxw: 100vw);
        @include space(margin, bottom, $spacer * 4);
      }
    }

    &--content {
      @include flex(null, center, null);
      flex: 1;
    }
  }

  &__content {
    @include padding($spacer * 6 0 0);
  }

  &__teaser {
    .fp-article__image {
      overflow: initial;
      position: relative;
    }
  }

  &__badge {
    @include font($text-m, $text-bold, normal);
    @include padding($spacer * 3 $spacer * 4 $spacer * 3  $spacer * 6 );
    background-color: $color-primary;
    bottom: calc($spacer * 2);
    color: $white;
    display: block;
    left: -calc($spacer * 2);
    line-height: $text-m;
    position: absolute;

    @media screen and (max-width: $mobile) {
      @include padding(calc($spacer * 2) $spacer * 4 calc($spacer * 2)  $spacer * 6 );
      bottom: -calc($spacer * 2);
      font-size: $text-s;
      left: 0;
    }
  }
}

.fp-gallery-list__teaser:nth-child(n + 2) {
  .fp-gallery-list__badge {
    @media screen and (max-width: $mobile) {
      left: -16px;
    }
  }
}
