// This file contains the styles for the REGISTER BANNER
// It is imported in the _components.scss file

.fp-register-banner {
  &__content {
    @include space(margin, bottom, calc($spacer * 2));
    @include space(padding, all, $spacer * 15);
    background: $gray;
    color: $white;
    font-family: $font-primary;
    font-weight: $text-bold;
    text-align: center;
  }

  &__logo-small {
    display: none;
  }

  &__title {
    @include space(padding, bottom, $spacer * 3);
    color: white;
  }

  &__text {
    color: white;
  }

  &__button {
    @include space(margin, top, $spacer * 8.5);
    @include padding($spacer * 4 $spacer * 6.5);
    @include transition(0.2s, $expo);
    background: $white;
    border: 0;
    color: $gray;
    cursor: pointer;
    display: inline-block;
    font-family: $font-primary;
    font-size: $text-l;
    font-weight: $text-bold;
    text-align: center;
    text-decoration: none;

    @media (max-width: 700px) {
      font-size: $text-m;
    }
  }

  &__icon {
    display: none;
  }

  &__text {
    @media (max-width: $tablet) {
      font-size: $text-l;
    }
  }
}

