.embed {
  box-sizing: border-box;
  margin: 24px 0;
  outline-offset: 2px;

  .placeholder {
    background-repeat: no-repeat;
    background-size: 100%;
  }

  &.mini-teaser-header {
    .placeholder {
      @include size(100%, null);
      @include flex();
      @include padding(0);
      background-image: none;

      article {
        @include flex();
        @include margins(0 $spacer * 4 0 0);
        flex: 1;

        &:last-child {
          @include margins(0);
        }
      }

      figure {
        @include size($spacer * 20, $spacer * 20);
        background: $light-gray;
      }

      aside {
        @include padding(0 0 0 calc($spacer * 2));
        flex: 1;
      }

      div {
        @include size(100%, $spacer * 6);
        @include margins(0 0 calc($spacer * 2));
        background: $gray;

        &:nth-child(2) {
          @include size(70%, $spacer * 6);
          @include margins(0);
        }
      }
    }
  }

  &.related-articles {
    article {
      @include size(100%, null);
      @include margins(0 0 $spacer-v-xs 0);

      &:last-child {
        @include margins(0);
      }

      div {
        @include size(50%, calc($spacer * 2));
        background: $dark-gray;

        &:nth-child(1) {
          @include size(20%, null);
          @include margins(0 0 $spacer-v-xxxs 0);
          background: $gray;
        }
      }
    }
  }

  &.single-teaser-small {
    @include padding($spacer * 6);
    border: $border-light-gray;

    .placeholder {
      @include padding(0);
      @include flex();
      background-image: none;

      figure {
        @include size(25%, 150px);
        @include margins(0 calc($spacer * 2) 0 0);
        background: $gray;
      }

      aside {
        flex: 1;
      }

      span {
        @include size(120px, calc($spacer * 2));
        @include margins(0 0 $spacer * 4 0);
        background: $light-gray;
        display: block;
      }

      div {
        @include size(100%, $spacer * 4);
        @include margins(0 0 calc($spacer * 2) 0);
        background: $light-gray;

        &:last-child {
          @include size(80%, $spacer * 4);
        }
      }
    }
  }

  &.fp-most-popular-topics {
    @include size(100%, null, $maxw: 1000px);
    @include margins(0 auto);

    &::before {
      background: $gray;
    }

    .fp-most-popular-topics__text {
      color: $gray;
    }

    .fp-most-popular-topics__header {
      background-color: $light-gray;
    }

    .fp-most-popular-topics__list-item {
      background: $gray;
    }

    .article-teaser .placeholder {
      @include flex(center, center, null);
      background: $light-gray;
      background-image: none;
      padding-top: 0;

      &::after {
        @include size(80%, calc($spacer * 2));
        @include margins($spacer * 4 0);
        background: $gray;
        content: '';
        display: block;
      }
    }
  }

  .fp-category-name-header {
    .fp-category-name-header__text {
      color: $gray;
    }

    svg {
      path {
        fill: $gray;
      }
    }
  }

  &.fp-region-placeholder {
    @include size(100%, $spacer * 15);
    @include margins(0);
    @include flex(center, center, null);
    background: $light-gray;

    > div {
      @include size(70%, $spacer * 4);
      background: $gray;
    }
  }

  &.embed-header {
    .placeholder {
      @include flex(null, center, null);

      div {
        @include size(40%, $spacer * 3);
        background: $dark-gray;

        &:nth-child(2) {
          @include size($spacer * 3, $spacer * 3);
          @include margins(0 0 0 calc($spacer-h-xxxs / 2));
        }
      }
    }
  }

  &.dynamic-vertical-teasers,
  &.topic-section {
    .placeholder {
      @include padding(0);
      background-image: none;

      article {
        @include margins(0 0 $spacer * 4 0);
        @include flex();
        @include size(100%, null);

        &:last-child {
          @include margins(0);
        }

        figure {
          @include size(400px, 150px);
          background: $light-gray;
        }

        aside {
          @include padding(0 0 0 $spacer * 6);
          flex: 1;

          div {
            @include size(100%, $spacer * 6);
            @include margins(0 0 $spacer * 4 0);
            background: $gray;

            &:last-child {
              @include margins(0);
            }

            &:nth-child(2) {
              @include size(70%, null);
            }

            &:nth-child(3) {
              @include size(30%, null);
            }
          }
        }
      }
    }
  }

  &.article-teaser {
    .placeholder {
      background: $light-gray;
      background-image: none;
      position: relative;
    }

    div:not(.placeholder) {
      @include size(100%, $spacer * 6);
      @include margins($spacer * 4 0 calc($spacer * 2) 0);
      background: $gray;
      content: '';

      &:last-child {
        @include margins(0);
        @include size(50%, null);
      }
    }
  }

  &.fp-breaking-news {
    .fp-breaking-news__title {
      background: $light-gray;
      border-right: $border-gray;

      h4 {
        color: $gray;
      }
    }

    .placeholder {
      @include size(100%, 100%);
      @include flex(center, center, null);
      background: $light-gray;

      div {
        @include size(80%, calc($spacer * 2));
        background: $gray;
      }
    }
  }
}

.fp-editor-container {
  @include size($container-width, null);
  @include margins(0 auto);

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    @include size($container-width-tablet, null);
  }

  @media (max-width: $mobile) {
    @include size(auto, null);
    @include margins(0 0.5rem);
  }

  @include padding($spacer-v-xxxl 0);

  .fp-gallery {
    .fp-carousel__next-arrow,
    .fp-carousel__prev-arrow {
      display: none;
    }

    .gallery-image {
      @include margins(0 0 $spacer-v-xxl 0);

      &:last-child {
        margin: 0;
      }
    }
  }

  .fp-infobox {
    &.fp-infobox--text-only {
      figure {
        display: none;
      }
    }
  }

  .gtm-ad-slot {
    display: none;
  }

  .fp-listing-teaser-elements__article {
    @include padding($spacer-v-xs 0);
    border-bottom: $border-dotted;
    margin-top: -0.0625rem;

    &:first-child {
      article {
        padding-top: 0;
      }
    }

    &:last-child {
      article {
        border-bottom: 0;
      }
    }
  }

  .fp-single-teaser {
    .dynamic-vertical-teasers {
      .placeholder {
        @include flex();
      }
    }

    &__small {
      .embed {
        margin-top: 0;

        .placeholder {
          flex-direction: column;

          figure {
            width: 100%;
          }
        }
      }
    }
  }

  .fp-three-teasers {
    .fp-single-teaser {
      .embed {
        &.dynamic-vertical-teasers {
          display: block;
        }
      }
    }
  }

  .fp-vertical-teasers-small {
    .placeholder {
      article {
        flex-direction: column;

        figure {
          margin-bottom: $spacer-v-xs;
          width: 100%;
        }
      }
    }
  }
}

.fp-multimedia {
  ld-include {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .embed {
    &.dynamic-vertical-teasers {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .placeholder {
        flex: 0.3;

        article {
          figure {
            background: #d0d0d0;
            width: 100%;
          }
        }
      }
    }
  }
}

// Gallery article editor styles

.container {
  &.doc-section {
    @include size($container-width, null);
    @include margins(0 auto);
    @include padding($spacer-v-xxxl 0);
    font-family: $font-primary;
    @media screen and (min-width: $mobile) and (max-width: $tablet) {
      @include size($container-width-tablet, null);
    }

    @media (max-width: $mobile) {
      @include size(auto, null);
      @include margins(0 0.5rem);
    }

    &__small {
      @include size(83.3333333333%, null);
      @include margins(0 auto);
    }

    .fp-gallery-carousel__controlls {
      display: none;
    }

    .fp-carousel__prev-arrow,
    .fp-carousel__next-arrow {
      display: none;
    }
  }
}

.fp-gallery-carousel {
  .embed {
    @include margins(0);

    .placeholder {
      @include size(100%, 400px);
      background: $dark-gray;
    }
  }
}

.placeholder__iframe,
.placeholder__free-html {
  @include size(100%, 300px);
  @include flex(center, center, null);
  background: $light-gray;

  p {
    @include font($text-m, $text-regular, normal);
    color: $dark-gray;
    text-align: center;
  }
}

.fp-three-teasers {
  ld-include {
    grid-column: 1 / -1;
  }
  
  .embed {
    &.dynamic-vertical-teasers {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(3, 1fr);

      .placeholder {
        article {
          figure {
            width: 100%;
          }
        }
      }
    }
  }

  .fp-listing-teaser-link-box__container,
  .fp-listing-teaser-single__container {
    .embed {
      &.dynamic-vertical-teasers {
        display: block;
      }
    }
  }
}

//{{FIX THE ABOVE}}

.fp-aufmacher {
  ld-include {
    grid-column: 1 / -1;
  }

  .embed {
    @include size($container-width, null, $maxw: $container-max-width);
    @include margins(0 auto);
    @media (max-width: $mobile) {
      @include size(auto, null, $maxw: $container-max-width);
      @include margins(0 auto);
    }
  }

  .placeholder {
    background: $light-gray;
    background-image: none;
    padding: 0 !important;
    position: relative;

    figure {
      @include padding(42.5% 0 0 0);
      background: $light-gray;
      background-image: none;
      position: relative;
    }

    aside {
      @include margins(-5rem $spacer-h-s $spacer-v-s $spacer-h-s);
      @include padding($spacer-v-s $spacer-h-s $spacer-v-m);
      background: $white;
      position: relative;

      span {
        @include size(120px, $spacer * 4);
        background: $dark-gray;
        display: block;
      }

      div {
        @include size(100%, $spacer * 6);
        @include margins($spacer * 4 0);
        background: $gray;
        content: '';

        &:nth-of-type(2) {
          @include size(70%, null);
        }

        &:nth-of-type(3) {
          @include margins(0);
          @include size(50%, null);
        }
      }
    }
  }
}

.fp-ressort-teasers {
  ld-include {
    grid-column: 1 / -1;
  }

  .placeholder__ad {
    @include size(100%, 100%);
    background: $light-gray;
  }
}

.fp-multimedia {
  ld-include {
    grid-column: 1 / -1;
  }
}

.fp-load-more-text-done {
  font-weight: bold;
}

.fp-load-more-btn {
  @include flex(space-between, center, null);
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 4px;
  cursor: pointer;
  padding: 16px;
  width: 100%;

  &__text {
    @include font(1rem, 700, null);
  }
}


.placeholder {
  &__tweet {
    @include size(initial, initial);
    @include padding($spacer * 4);
    border: $border-light-gray;
    border-radius: calc($spacer * 2);

    &--header {
      @include flex(space-between, flex-start, null);

      &--image {
        @include size($spacer * 12, $spacer * 12);
        @include margins(0 calc($spacer * 2) 0 0);
        background: $light-gray;
        border-radius: 100%;
      }

      &--content {
        @include flex(null, null, column);
        flex: 1;

        div {
          @include size(50%, $spacer * 4);
          @include margins(0 0 calc($spacer * 2) 0);
          background: $light-gray;
          border-radius: $spacer;

          &:nth-child(2) {
            @include margins(0);
            @include size(20%, $spacer * 3);
          }
        }
      }

      &--logo {
        @include size($spacer * 8, $spacer * 8);
        background: $light-gray;
        border-radius: 100%;
      }
    }

    &--body {
      @include size(100%, 280px);
      @include margins($spacer * 4 0 0);
      border: $border-light-gray;
      border-radius: calc($spacer * 2);

      div {
        @include size(100%, 210px);
        background: $light-gray;
        border-radius: calc($spacer * 2) calc($spacer * 2) 0 0;
      }
    }

    &--footer {
      @include size(100%, null);
      @include flex(null, center, null);
      @include margins($spacer * 4 0 0 0);

      div {
        @include size(120px, $spacer * 4);
        background: $light-gray;
        border-radius: $spacer;

        &:nth-child(1) {
          @include margins(0 calc($spacer * 2) 0 0);
        }
      }
    }
  }
}
