.fp-classified-ad-markets {
  @include margins(0 0 $spacer-v-xxxl 0);
}

.fp-classified-ad-markets-accordion {
  &__container {
    @include margins(0 0 calc($spacer-v-xxxs / 2) 0);

    &.open {
      .fp-classified-ad-markets-accordion__header {
        background: $border-color;
        border: 0.05rem solid $border-color;
        border-radius: 4px 4px 0 0;

        h3 {
          color: $white;
        }

        svg {
          transform: rotate(90deg);

          path {
            fill: $white;
          }
        }
      }

      .fp-classified-ad-markets-accordion__content {
        height: auto;
      }
    }

    &.closed {
      .fp-classified-ad-markets-accordion__header {
        background: $light-gray;
        border: 0.05rem solid $border-color;
        border-radius: 4px;

        &:hover {
          background-color: $border-color;

          h3 {
            color: $color-primary;
          }

          svg {
            path {
              fill: $color-primary;
            }
          }
        }

        h3 {
          color: $text-color;
        }

        svg {
          transform: rotate(0deg);

          path {
            fill: $text-color;
          }
        }
      }

      .fp-classified-ad-markets-accordion__content {
        height: 0;
      }
    }
  }

  &__header {
    @include flex(null, center, row);
    @include size(null, 50px, $minh: 0);
    @include padding($spacer-v-xxxs $spacer-h-xxxs $spacer-v-xxxs $spacer-v-xxs);
    background-color: $light-gray;
    border: 0.05rem solid $border-color;
    border-radius: 4px;
    cursor: pointer;

    h3 {
      color: $text-color;
      font-size: $text-l;
    }

    svg {
      @include size(36px, 16px);
      @include transition(0.2s, $expo);

      path {
        fill: $text-color;
      }
    }
  }

  &__content {
    @include flex(null, null, column);
    @include transition(0.3s, $expo);
    border: 0.05rem solid $border-color;
    border-radius: 0 0 4px 4px;
    border-top: 0;
    overflow: hidden;

    &__row {
      @include padding($accordion-spacer);
      @include margins(0 0 $accordion-spacer 0);
      display: grid;
      grid-gap: $accordion-spacer;
      grid-template-columns: 1fr 1fr;
      padding-top: 0;

      &:first-child {
        padding-top: $accordion-spacer;
      }

      &:last-child {
        align-items: center;
      }

      @media(max-width: $mobile) {
        grid-template-columns: 1fr;
      }
    }

    &__autofill {
      display: contents;

      &.fp-accordion-teaser-image--hidden {
        .fp-classified-ad-markets-accordion__content__teaser__image {
          display: none;
        }
      }
    }

    &__logo-link {
      figure {
        max-width: 70%;

        img {
          height: auto;
          object-fit: contain;
          width: 100%;
        }
      }

    }

    &__link {
      &:hover {
        .fp-link__secondary {
          svg {
            path {
              fill: $color-primary;
            }
          }
        }
      }

      .fp-link__secondary {
        @include margins(0);
        @include font($text-m, $text-regular, normal, $lh: $lh-m);
        align-items: center;
        display: inline-flex;

        svg {
          @include margins(0 0 0 $spacer-h-xxxs);
          height: auto;
          width: 8px;

          path {
            fill: $text-color;
          }
        }
      }
      
      .fp-link__secondary-deprecated {
        display: none;
      }
    }

    &__teaser {
      &.fp-accordion-teaser-image--hidden {
        .fp-classified-ad-markets-accordion__content__teaser__image {
          display: none;
        }
      }

      &__title {
        @include font($text-m, $text-bold, normal, $lh: $lh-m);
        @include margins(0 0 $spacer-v-xxs 0);
        color: $text-color;

        &:hover {
          color: $color-primary;
        }
      }
      
      &__excerpt {
        @include margins(0 0 $spacer-v-xxxs 0);
        line-height: $lh-m;
      }

      &__image {
        @include size(100%, 0);
        @include padding(0 0 66.66% 0);
        @include margins(0 0 $spacer-v-s 0);
        display: block;
        overflow: hidden;
        position: relative;
        @media(max-width: $tablet) {
          @include padding(0 0 50% 0);
        }

        @media(max-width: $mobile) {
          @include size(100%, null);
          @include padding(0 0 60% 0);
          @include margins(0 0 $spacer-v-s 0);
        }

        img {
          @include size(100%, 100%);
          object-fit: cover;
          position: absolute;
        }
      }
    }
  }
}
