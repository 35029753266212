.fp-three-teasers {
  @include margins(0 0 $spacer-v-xxxl 0);
  @media(max-width: $tablet) {
    @include margins(0 0 $spacer-v-xl 0);
  }

  &.fp-border-l {
    @include padding(0 0 $spacer-v-s 0);
    border-bottom: $border-l;
  }

  &.fp-grid-row {
    grid-row-gap: $spacer-v-s;
    @media(max-width: $tablet) {
      grid-auto-rows: auto;
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }

  .fp-col-4 {
    @media(max-width: $tablet) {
      grid-column: span 1;
    }
  }

  &__single {
    .fp-col-4 {
      @media(max-width: $tablet) {
        @include padding(0);
        @include margins(0);
        border-bottom: 0;
        grid-column: span 1;
      }
    }
  }
}
