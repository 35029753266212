// This file contains all the ARTICLE TABLE styles
// This file is imported into the _article.scss file

.fp-table {
  @include margins(0 20% 0 0);
  @include font($text-m, $text-regular, normal, $lh: $lh-m);

  @media(max-width: $mobile) {
    @include margins(0);
    width: 100%;
  }

  tr {
    &:first-child {
      td {
        border-top: 0;
      }
    }
  }

  td {
    @include padding(0.3571428571rem 0.7142857143rem);
    background-color: $white;
    border-top: 1px solid #d2d2d2;
  }
}

