// This file includes all the BASE styles for the project
// It is imported in the _basics.scss file

* {
  box-sizing: border-box;
}

html,
body {
  @include font(16px, $text-regular, normal, $family: $font-primary);
  @include size(null, null, $minh: 100vh);
  background: $white;
  color: $text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
}

body {
  @include size(null, null, $minh: 100vh);
  @include flex(null, null, column);

  // stylelint-disable selector-no-qualifying-type
  &.overflow-hidden {
    overflow: hidden;
  }
}

p {
  line-height: 1.5;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $text-color;
}
//
a {
  text-decoration: none;
}

.fp-special-publication {
  @include font($text-s, $text-regular, normal, $lh: $lh-s);
  @include margins(0 auto calc($spacer-v-xxxs / 2) auto);
  color: #a39e99;
  flex: 0 0 auto;
}
//
//em {
//  @include font(null, null, italic);
//}
//
//strong {
//  @include font(null, bold, null);
//}
//
////.lazyload { //TODO DSJ THIS NEEDDS TO BE ADAPTED TO ONLY SHOW THIS CLASS IN THE DELIVERY
////  filter: blur(10px);
////}
