// This file contains all the INTERNAL TEASER styles
// This file is imported into the _article.scss file

.fp-internal-teaser {
  @include padding($spacer-v-xs);
  @include margins(0 0 $spacer-v-s 0);
  border: $border-solid;

  &__header {
    @include size(100%, null);
    @include margins(0 0 $spacer-v-xxs 0);

    @media(max-width: $tablet) {
      display: none;
    }

    &--responsive {
      @include margins(0 0 $spacer-v-xxs 0);
      display: none;
      @media(max-width: $tablet) {
        display: block;
      }
    }
  }

  &__subheader {
    @include flex(null, center, null);
    @include margins(0 0 $spacer-v-xxs 0);
  }

  &__date {
    @include font($text-m, $text-regular, normal);
    @include margins(0 $spacer-h-m 0 0);
    color: $dark-gray;
  }

  &__subheadline {
    @include font($text-m, $text-bold, normal, $lh: $lh-m);
    color: $text-color;
  }

  &__title {
    @include font($text-xl, $text-bold, normal);
    color: $text-color;

    &:hover {
      color: $color-primary;
    }
  }

  &__image {
    @include size(25%, null, $minh: 1px);
    display: inline-block;

    @media(max-width: $tablet) {
      @include margins(0 0 $spacer-v-s 0);
      width: 100%;
    }

    img {
      @include size(100%, 100%, $maxw: 180px);
      object-fit: cover;
      @media(max-width: $tablet) {
        max-width: 100%;
      }
    }
  }

  &__intro {
    @include flex();
    @media(max-width: $tablet) {
      flex-direction: column;
    }
  }

  &__content {
    flex: 1;
  }

  &__city {
    @include font($text-m, $text-regular, normal);
    color: $dark-gray;
    display: inline;
    font-weight: 700;
  }

  &__excerpt,
  &__author {
    @include font($text-m, $text-regular, normal);
    color: $text-color;
    display: inline;
  }

  &__author {
    color: $dark-gray;
  }
}
