.fp-listing-teaser-elements {
  margin-bottom: $spacer-v-xl;

  .fp-cta-link__title {
    border-bottom: 0;

    &:not([href]) {
      cursor: auto;
    }

    &:hover {
      border-bottom-color: unset;
    }
  }

  &__body {
    @include padding($spacer-v-s $spacer-h-s);
    border: $border-solid;
  }

  &__container {
    @include size(100%, null);
  }

  &__carousel {
    @include flex(center, null, null);
    border-top: $border-dotted;
    padding-top: $spacer-v-s;
  }

  &__content {
    @include flex(null, flex-start, null);
  }

  &__header {
    flex: 1;
  }

  &__list {
    .slick-slide {
      div {
        article {
          @include padding($spacer-v-xs 0);
          border-bottom: $border-dotted;
          margin-top: -0.0625rem;
        }

        &:first-child {
          article {
            padding-top: 0;
          }
        }

        &:last-child {
          article {
            border-bottom: 0;
          }
        }
      }
    }
  }

  &__catchline {
    @include font($text-m, $text-bold, normal);
    @include margins(0 0 calc($spacer-v-xxxs / 2) 0);
    color: $text-color;

    @media (max-width: $tablet) {
      font-size: $text-m;
      line-height: $lh-m;
    }
  }

  &__title {
    @include font($text-l, $text-bold, normal, $lh: $lh-l);
    @include margins(0 0 $spacer-v-xxxs 0);

    @media (max-width: $mobile) {
      @include font($text-l, null, null);
    }

    &:hover {
      color: $color-primary;
    }
  }

  &__date {
    @include flex(null, null, row, wrap);
    @include font($text-s, $text-regular, normal);
    color: $dark-gray;
  }
}

.fp-ad-indicator {
  p {
    color: $dark-gray;
    font-size: $text-s;
  }
}
