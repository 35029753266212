.fp-cta-link {
  @include margins(0 0 $spacer-v-s 0);
  @include flex(null, center, null);
  cursor: pointer;

  &__title {
    @include font($text-m, $text-bold, normal, $lh: $lh-m);
    border-bottom: medium solid $white;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;

    a {
      color: inherit;
    }

    &:hover {
      border-bottom-color: $color-primary;
    }
  }

  svg {
    @include size(0.5rem, null);
    @include margins(0 0 0 calc($spacer-h-xxxs / 2));

    path {
      fill: $color-primary;
    }
  }
}
