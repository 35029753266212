// This file contains all the HEADER MINI TEASERS styles
// This file is imported into the _components.scss file

.fp-mini-teasers {
  @include size(100%, null, $maxw: $container-max-width);
  @include margins(0 auto);

  @include padding($spacer * 4 0 0);
  @media (max-width: $tablet) {
    @include padding(calc($spacer * 2) 0 0);
  }

  @media (max-width: $mobile) {
    @include size(95%, null, $maxw: $container-max-width);
  }

  .fp-grid-row {
    @include grid(1fr 1px 1fr 1px 1fr, null, null);
    @include padding(calc($spacer * 2) 0 0);

    @media(max-width: $tablet) {
      @include grid(1fr, 0, auto);
      @include padding(0 0 0 $spacer * 4);
    }
  }

  hr {
    @include size(1px, 90px);
    @include margins(0);
    background: $color-primary-dark;
    border: 0;
    outline: none;

    &:nth-of-type(3) {
      display: none;
    }

    @media(max-width: $tablet) {
      display: none;
    }
  }

  &__item {
    @include flex(flex-start, flex-start, column);

    &:hover {
      .fp-mini-teasers__title h1 {
        text-decoration: underline;
      }

      .fp-mini-teasers__image {
        img {
          @include scale(1.03);
        }
      }
    }

    @media(max-width: $tablet) {
      @include padding(calc($spacer * 2) 0);
      border-bottom: $border-light-gray;
    }
  }

  &__image {
    @include size($spacer * 15, $spacer * 15);
    overflow: hidden;

    img {
      @include responsive-image();
      @include transition(0.1s, ease);
    }

    @media(max-width: $tablet) {
      display: none;
    }
  }

  &__catchline {
    @include font($text-s, $text-bold, normal);
    @include padding(0 0 calc($spacer * 2));
    color: $color-primary;

    @media(max-width: $tablet) {
      display: none;
    }
  }

  &__title {
    flex: 1;

    @media(max-width: $tablet) {
      display: list-item;

      &::marker {
        color: $text-color;
      }
    }

    h1 {
      @include font($text-m, $text-bold, normal, $lh: 1.3);
      @include elipsis(3);
      @include margins(0 $spacer 0 0);
      color: $text-color;
    }
  }

  &__details {
    @include size(null, 100%);
    @include flex();
    @include margins(0);
    flex: 1;
    position: relative;
  }
}
