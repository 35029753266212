.fp-ad-block {
  @include size(18.75rem, 37.5rem);
  @include margins(0 auto);
  @include padding($spacer-v-xxs $spacer-h-xxs);
  background: $ad-yellow;

  @media(max-width: $tablet) {
    @include size(18.75rem, 300px);
  }

  p {
    @include font($text-m, $text-bold, null, $lh: $lh-m);
    color: $white;
  }
}
