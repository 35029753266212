.fp-listing-container {
  @include margins(0 0 $spacer-v-xxxl 0);

  &--elements {
    .fp-listing-frame {
      @include padding($accordion-spacer);
      border: $border-solid;
    }
  }

  &--single {
    .fp-article--listing-teasers-single {
      .fp-article__lead {
        display: none;
      }
    }

    &.fp-teaser-text--visible {
      .fp-article--listing-teasers-single {
        .fp-article__lead {
          display: block;
        }
      }
    }
  }
}
