// This file contains all the CATEGORY NAME styles
// This file is imported into the _components.scss file

.fp-category-name-header {
  @include padding(calc($spacer * 2) 0);
  @include margins(0 0 $spacer-v-xxs 0);
  border-bottom: $border-solid;

  @media (max-width: $mobile) {
    @include margins(0 0 $spacer * 4 0);
    border: 0;
    position: relative;
    text-align: center;

    &::before,
    &::after {
      @include size(100%, 1px);
      background: $color-primary;
      content: '';
      display: block;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::after {
      left: initial;
      right: 0;
    }
  }

  &__content {
    @include flex(flex-start, center, null);

    @media (max-width: $mobile) {
      @include flex(center, center, null);
      @include padding(0 $spacer * 4);
      background: $white;
      display: inline-flex;
      position: relative;
      z-index: 5;
    }

    &:hover {
      svg {
        @include translate($spacer, 0);
      }
    }

    a {
      align-items: center;
      display: flex;
    }
  }

  &__text {
    @include flex(null, flex-end, null);
    @include font($text-xxxl, $text-bold, normal);
    color: $text-color;
    line-height: 1;

    @media (max-width: $mobile) {
      align-items: center;
      font-size: $text-m;

      &.text-24--bold {
        font-size: $text-m;
      }
    }

    a {
      color: inherit;

      &:visited {
        color: $color-primary;
      }
    }
  }

  svg {
    @include margins(0 0 0 calc($spacer * 2));
    @include transition(0.2s, $expo);
    @media (max-width: $mobile) {
      @include margins(calc($spacer / 2) 0 0 calc($spacer * 2));
    }
  }

  &__thema {
    @include margins(0 calc($spacer * 2) 0 0);
    color: $color-primary-dark;
  }

  &__exclusive {
    @include flex(space-between, center, null);
    @media(max-width: $mobile) {
      @include flex(null, null, column);

      .fp-category-name-header__content {
        > a {
          @include padding($spacer * 4 0 0);
        }

        > svg {
          margin-top: $spacer * 4;
        }
      }

      span {
        @include translate(0, $spacer * 3 );
      }
    }
  }
}
