@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-weight: 700;
  src: url('/design/source/fonts/Lato-Bold.ttf');
}

@font-face {
  font-display: swap;
  font-family: 'Lato';
  font-weight: 400;
  src: url('/design/source/fonts/Lato-Regular.ttf');
}
