@import './modifiers/newsletter-in-components';
@import './modifiers/newsletter-envelope';

.fp-newsletter__opt-in-modul {
  &__wrapper {
    @include margins(0 0 $spacer-v-xxs 0);
    @include padding($spacer-v-xxxl 0 $spacer-v-s 0);
    border-bottom: $border-dotted;
  }

  &__info-wrapper,
  &__legal-text {
    grid-column: span 8;
  }

  &__ghost-div {
    grid-column: span 4;
  }

  &__image {
    grid-column: span 4;

    img {
      width: 100%;
    }
  }

  &__catchline {
    @include font(16px, bold, normal, $lh: 22px);
  }

  &__title { 
    @include font(25px, bold, normal, $lh: 34px);
    @include margins(15px 0);

    &:hover {
      color: $color-primary;
    }
  }

  &__description { 
    font-size: 16px;
  }

  &__input {
    @include size(100%, 45px);
    @include padding(12px);
    background: 0% 0% no-repeat padding-box;
    border: 1px solid $border-color;
    border-radius: 3px;

    grid-column: span 8;
  }

  &__submit {
    @include size(100%, 45px);
    @include padding(12px);
    align-items: center;
    background: $color-primary 0% 0% no-repeat padding-box;
    border-radius: 3px;
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 15px;

    grid-column: span 4;
    justify-content: center;

    &:hover {
      background-color: $text-color;
    }
  }

  &__legal-text {
    color: $text-color;
    font-size: 13px;
    letter-spacing: 0;

    a {
      color: $color-primary;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__form {
    @include margins(15px 0);
  }

  &__message {
    @include margins(0 0 $spacer-v-xxxs 0);

    &-error {
      color: red;
    }

    &-success {
      color: green;
    }
  }
}

.placeholder {
  background-color: $light-gray;
  grid-column: span 4;
  height: 100%;
  width: 100%;
}

@media (max-width: $tablet) {
  .fp-newsletter__opt-in-modul {
    &__input {
      grid-column: span 7;
    }

    &__submit {
      grid-column: span 5;
    }
        
    &__title {
      @include margins(5px 0);
      font-size: 20px;
    }
  }
}

@media (max-width: $mobile) {
  .fp-newsletter__opt-in-modul {
    &__info-wrapper,
    &__legal-text,
    &__image,
    &__input,
    &__submit {
      grid-column: span 12;
    }

    &__ghost-div {
      display: none;
    }

    &__catchline,
    &__title,
    &__description,
    &__legal-text {
      text-align: center;
    }

    &__message {
      &-error,
      &-success {
        text-align: center;
      }
    }
  }
}

// The following styles are applied when the component is a child of the 3 Column Container 30/30/30 component
.fp-col-4 .fp-newsletter__opt-in-modul__legal-text {
  grid-column: span 12;
}

.fp-col-4 .fp-newsletter__opt-in-modul__ghost-div {
  display: none;
}

