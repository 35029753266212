.fp-newsletter__opt-in-modul__envelope {
  background: url('/design/source/images/envelope.svg') center no-repeat;
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

@media (min-width: $mobile) and (max-width: $tablet) {
  .fp-newsletter__opt-in-modul__envelope {
    height: 18px;
    margin-right: 8px;
    width: 18px;
  }
}
