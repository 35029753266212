.fp-main-title {
  h1 {
    @include font($text-xxxl, $text-bold, normal, $lh: $lh-xxxl);
    @include margins(0 0 $spacer-v-m 0);
    @media (max-width: $mobile) {
      font-size: $text-xxl;
      line-height: $lh-xxl;
    }
  }

  &.text__right {
    text-align: right;
  }

  &.text__center {
    text-align: center;
  }
}
