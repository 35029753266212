.fp-article {
  &__main-image {
    @include margins(0 0 $spacer-v-m 0);
  }

  &.fp-container {
    @include size(auto, null);
    @include margins(0);
    background-color: $white;
  }

  .fp-container__small {
    @media(max-width: $mobile) {
      @include size(100%, null);
    }
  }

  .fp-figure {
    @include size(100%, auto, $maxw: 100%, $maxh: 730px);
    padding-bottom: 0;

    img {
      @include size(100%, auto, $maxh: 730px);
      object-fit: contain;
      position: static;
    }

    amp-img {
      img {
        position: absolute;
      }
    }
  }
}

.fp-figure {
  @include margins(0);
}

.fp-image-info {
  @include font($text-m, $text-regular, normal, $lh: $lh-m);
  @include margins(0.215rem 0 0 0);
  color: $text-color;

  &__caption {
    @include margins(0 $spacer 0 0);
  }

  &__source {
    color: $dark-gray;
  }
}

.fp-paragraph {
  @include font($text-l, $text-regular, normal, $lh: $lh-l);
  @include margins(0 0 $spacer-v-m 0);
  color: $text-color;

  a {
    color: $color-primary;

    &:hover {
      text-decoration: underline;
    }
  }

  &.text__right {
    text-align: right;
  }

  &.text__center {
    text-align: center;
  }
}

.fp-subtitle {
  @include margins(0 0 $spacer-v-xs 0);
  @include padding(0);
  @include font($text-xxl, $text-bold, normal, $lh: $lh-xxl);
  color: $text-color;

  &.text__right {
    text-align: right;
  }

  &.text__center {
    text-align: center;
  }
}

.fp-subheading {
  @include margins(0 0 $spacer-v-xs 0);
  @include padding(0);

  h3 {
    @include font($text-xxl, $text-bold, normal, $lh: $lh-xxl);
    color: $text-color;
  }

  &.text__right {
    text-align: right;
  }

  &.text__center {
    text-align: center;
  }
}

.fp-image {
  @include margins(0 0 $spacer-v-m 0);
}

.fp-color-format-primary {
  color: $color-primary;
}

.fp-format-strike {
  text-decoration: line-through;
}

.fp-format-title-divider::before {
  @include margins(auto $spacer-v-xxs auto $spacer-v-xxs);
  border-color: #c6c1ba;
  border-left: 2px solid;
  color: #c6c1ba;
  content: '';
  height: 25px;
}

@media (max-width: 785px) {
  .fp-format-title-divider::before {
    @include margins(auto $spacer-v-xxxs auto $spacer-v-xxxs);
    height: 25px;
  }
}
