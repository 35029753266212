// This file contains all the AUFMACHER styles
// This file is imported into the _components.scss file


.fp-aufmacher {
  header {
    @include margins(0 0 $spacer-v-s 0);
    @media(max-width: $tablet) {
      @include margins(0 0 $spacer-h-xxs 0);
    }
  }

  &__image {
    @include size(100%, 0);
    @include padding(0 0 50% 0);
    display: block;
    overflow: hidden;
    position: relative;
    @media(max-width: $mobile) {
      @include padding(0 0 66.66666% 0);
    }

    img {
      @include size(100%, 100%);
      object-fit: cover;
      object-position: top;
      position: absolute;
    }
  }

  &__content {
    @include margins(-5rem $spacer-h-s $spacer-v-s $spacer-h-s);
    @include padding($spacer-v-s $spacer-h-s $spacer-v-m);
    background: $white;
    position: relative;
    z-index: 2;

    &--inner {
      @include padding(0 0 0 $spacer-h-xxs);
      border-left: $border-dotted;
      @media(max-width: $mobile) {
        @include padding(0);
        border: 0;
      }
    }

    @media(max-width: $mobile) {
      @include margins(-2.5rem $spacer-h-xxs $spacer-v-s $spacer-h-xxs);
      @include padding($spacer-v-xs $spacer-h-xxs $spacer-v-m);
      border-bottom: $border-dotted;
    }
  }

  &__catchline {
    @include font($text-l, $text-bold, normal);
    @include margins(0 0 $spacer-h-xxxs 0);

    @media (max-width: $tablet) {
      @include font($text-m, null, null, $lh: $lh-m);
    }
  }

  &__title {
    @include font($text-xxl, $text-bold, normal);

    @media (max-width: $tablet) {
      @include font($text-xl, null, null);
    }

    @media (max-width: $mobile) {
      @include font($text-l, null, null);
    }

    &:hover {
      color: $color-primary;
    }

    &.fp-paid-article__article {
      &::before {
        float: none;
      }
    }
  }

  &__intro {
    @include margins(0 0 $spacer-h-xxxs 0);
  }

  &__city {
    @include margins(0 $spacer-v-xxxs 0 0);
    color: $dark-gray;
    font-weight: bold;
  }

  &__details {
    @include flex(null, center, null);
  }

  &__author,
  &__date {
    @include font($text-s, $text-regular, normal);
    color: $dark-gray;
  }

  &__date {
    @include flex(null, null, row, wrap);
  }

  &__author {
    @include padding(0 $spacer-h-xxxs 0 0);
  }

  &__date {
    @include padding(0 0 0 $spacer-h-xxxs);
    border-left: 1px solid $dark-gray;
  }
}
