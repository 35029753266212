.fp-gallery-carousel {
  @include size(100%, null);
  @include margins(0 0 $spacer-v-m 0);

  &.doc-component {
    .fp-gallery-carousel__container {
      display: block;

      .fp-gallery-carousel__images--article {
        display: none !important;

        &:first-child {
          display: flex !important;
        }
      }
    }
  }

  &__controlls {
    @include size(100%, 3.125rem);
    @include flex(space-around, center, null);
    background: $light-gray;
    color: $dark-gray;

    &__progress {
      @include font($text-m, $text-regular, normal, $lh: $lh-m);
      flex: 1;
      text-align: center;
    }
  }

  &__container {
    @include flex();
  }

  .fp-carousel__prev-arrow,
  .fp-carousel__next-arrow {
    @include flex(center, center, null);
    @include size(33%, null);

    svg {
      @include size(16px, 32px);

      path {
        fill: $dark-gray;
      }
    }
  }

  &__images {
    @include size(100%, auto);

    &--article {
      @include size(100%, auto);
    }

    &--image {
      @include size(100%, auto);

      img {
        @include size(100%, auto);
        @include margins(0 0 2px 0);
      }
    }

    &--caption {
      @include padding(0 0 $spacer-v-m 0);
      color: $text-color;
    }

    &--source {
      color: $dark-gray;
    }
  }


  .slick-slider {
    touch-action: auto !important;
  }

  .slick-list,
  .slick-track {
    @include size(null, auto);
  }

  .fp-gallery-carousel__title {
    padding: 0;
  }

  .fp-gallery__info--caption {
    a {
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
