// Resort master
.fp-ressort {
  @include margins(0 0 $spacer-v-xxxl);
  border-bottom: $border-l;

  &__navigation {
    @include padding($spacer-v-xxl $spacer-h-s);
    background-color: $light-gray;
    @media(max-width: $tablet) {
      @include padding($spacer-h-s);
    }

    header {
      @include margins(0 0 $spacer-v-xs 0);
      @media(max-width: $tablet) {
        @include margins(0);
      }

      .fp-link__primary {
        @include margins(0);
      }

      svg {
        margin-left: 0.5rem;
        position: relative;
        top: 0.25rem;
        width: 0.7rem;
      }
    }
  }

  &__teasers,
  &__navigation {
    @media(max-width: $tablet) {
      grid-column: 1 / -1;
    }
  }

  &__links {
    @media(max-width: $tablet) {
      display: none;
    }

    .fp-link__secondary {
      @include margins(0 0 $spacer-v-xs 0);
      border-bottom-color: #a39e99;
    }
  }
}

// Ressort variations

.fp-ressort-teasers {
  @include padding(0 0 $spacer-v-l 0);

  &.fp-grid-row {
    grid-row-gap: $spacer-v-l;
  }

  &__with-aufmacher-ad,
  &__with-aufmacher-small-teasers {
    aside {
      .fp-col-6 {
        @media(max-width: $tablet) {
          grid-column: 1 / -1;
        }
      }
    }

    .fp-aufmacher__image {
      @include padding(0 0 66.66666% 0);
    }

    .fp-aufmacher__catchline {
      font-size: $text-m;
      line-height: $lh-m;
    }

    .fp-aufmacher__title {
      font-size: $text-xl;
      line-height: $lh-xl;
    }
  }

  &__with-aufmacher-ad,
  &__with-aufmacher-small-teasers {
    &.fp-grid-row {
      grid-row-gap: 0;
    }
  }

  &__with-small-teasers {
    .fp-col-6 {
      @media(max-width: $tablet) {
        grid-column: 1 / -1;
      }
    }
  }
}
