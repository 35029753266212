.fp-ad-powerstories {
  @include margins($spacer-v-m auto $spacer-v-m auto);

  &__placeholder {
    @include size(100%, 90px);
    @include flex(center, center, null);
    @include font($text-m, $text-bold, null, $lh: $lh-m);
    background: $special-color-1;
    color: $white;
  }
}

.power-stories-embed-section {
  @include margins(0 0 $spacer-v-m 0);
}
