// This file contains all the CATEGORY SECTION styles
// This file is imported into the _components.scss file

.fp-category-section {
  &__main-article {
    @include space(padding, bottom, 24px);
    @include space(margin, bottom, 24px);
    border-bottom: 1px solid $light-gray;

    @media (max-width: 750px) {
      @include space(padding, bottom, 0);
      border: 0;
    }

    .fp-article__title {
      @media (max-width: 750px) {
        @include font($text-m, $text-bold, normal);
      }
    }
  }
}
