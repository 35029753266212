.fp-authors-heading {
  margin-top: $spacer-v-xl;

  &--additional-info {
    margin-top: $spacer-v-xl;
  }
}

.fp-authors-personal-info {
  &--full-name {
    @include font($text-xl, $text-bold, oblique, $lh: $lh-xxl);
    display: flex;
    gap: 8px;
  }

  &--department {
    @include font($text-l, $text-regular, oblique, $lh: $lh-l);
  }
}

.fp-authors-additional-info {
  &--contact p,
  &--focus p {
    @include font($text-l, $text-bold, normal, $lh: $lh-l);
    margin-bottom: $spacer-v-xxs;
  }

  &--contact-icons a {
    &:hover {
      svg path {
        fill: $color-primary;
      }
    }
  }

  &--link {
    @include font($text-m, $text-regular, normal, $lh: $lh-m);

    margin-top: $spacer-v-xxxl;

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: $color-primary;
        }
      }

      div {
        border-color: $color-primary;

        a {
          color: $color-primary;
        }
      }
    }

    svg {
      @include margins(0 0 0 $spacer-h-xxxs);
    }
  }

  &--focus {
    margin-bottom: $spacer-v-xl;
  }

  &--focus-item {
    @include padding($spacer-v-xxxs $spacer-v-xxs);
  }

  &--description {
    @include font($text-l, $text-regular, normal, $lh: $lh-l);
  }
}

.fp-authors-top-articles,

.fp-authors-further-articles {
  h1 {
    @include font($text-xl, $text-bold, normal, $lh: $lh-xxl);
    @include margins($spacer-v-xl 0);
  }
}

.fp-authors-further-articles {
  &--three-teasers {
    &.fp-border-l {
      border-bottom: $border-l;
    }
  }
}

.fp-authors-further-articles-grid-row {
  grid-gap: $spacer-v-s;
}

.fp-authors-further-articles-three-teasers {
  &.fp-authors-border-l {
    border-bottom: $border-l;
  }
}
