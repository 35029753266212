@use 'sass:color';

// This file is a reference point for all the VARIABLES
// Each variable is commented and has basic instructions
// It is imported in the _basics.scss file

// Basic unit variables
$spacer: 4px;

// Transition variables
$expo: cubic-bezier(0.87, 0, 0.13, 1);

// override any variable below

$paid-article-logo: '/design/source/images/wn+_rot.svg';
$paywall-plus-logo: '/design/source/images/logo-wn-plus.svg';
$paywall-logo: '/design/source/images/logo-wn-metered.svg';
$paywall-logo-flyin: '/design/source/images/logo-wn.png';
$paywall-logo-width: 15rem;
$paywall-logo-height: 53px;

// Responsive variables
$mobile: 785px;
$tablet: 1171px;

// Container variables
$container-max-width: 1000px;
$container-width: 61.625rem;
$container-width-tablet: 49.25rem;


// Color Variables
$white: #fff;
$black: #000;
$color-primary: #ba0000;
$text-color: #323232;
$light-gray: #efece8;
$gray: #d4cfc8;
$dark-gray: #a39e99;
$special-color-1: #7ab637;
$special-color-2: #edf6e2;
$color-primary-dark: color.scale($color-primary, $lightness:10%);
$ad-yellow: #d5bb87;
$border-color: #c6c1ba;
$pw-background: #f7f6f4;

// Social Media Color Variables
$facebook: #3b589c;
$twitter: #28aae1;
$print: #6b6763;
$mail: #ffa625;

// Border Variables
$border-l: 0.4rem solid $light-gray;
$border-dotted: 0.0625rem dotted $border-color;
$border-solid: 0.0625rem solid $border-color;

$border-black: 1px solid $black;
$border-white: 1px solid $white;
$border-dark-gray: 1px solid $dark-gray;
$border-gray: 1px solid $gray;
$border-light-gray: 1px solid $light-gray;

// Typography Variables
$font-primary: 'Lato', sans-serif;

$text-s: 0.8125rem;
$text-m: 1rem;
$text-l: 1.25rem;
$text-xl: 1.563rem;
$text-xxl: 1.953rem;
$text-xxxl: 2.441rem;
$text-article-catchline-m: 1.2504rem;

$text-regular: 400;
$text-bold: 700;

// Line Heght Variables
$lh-xxxl: 3.173rem;
$lh-xxl: 2.539rem;
$lh-xl: 2.1rem;
$lh-l: 1.875rem;
$lh-m: 1.4rem;
$lh-s: 1.125rem;
$lh-article-catchline-m: 1.68rem;


// Space Variables

// Vertical Variables
$spacer-v-xxxs: 0.5rem;
$spacer-v-xxs: 0.75rem;
$spacer-v-xs: 1rem;
$spacer-v-s: 1.25rem;
$spacer-v-m: 1.5rem;
$spacer-v-l: 1.75rem;
$spacer-v-xl: 2rem;
$spacer-v-xxl: 2.5rem;
$spacer-v-xxxl: 3rem;

// Horizontal Variables
$spacer-h-xxxs: 0.5rem;
$spacer-h-xxs: 0.75rem;
$spacer-h-xs: 1rem;
$spacer-h-s: 1.25rem;
$spacer-h-m: 1.5rem;

$accordion-spacer: 1.3125rem;
$expandable-image-padding: 485px;
$expandable-image-padding-tablet: 378px;


// Ad Variables

// Billboard Ad
$billboard-w: 970px;
$billboard-w-t: 786px;
$billboard-w-m: 300px;
$billboard-h: 250px;

// Anker Ad
$anker-w: 729px;
$anker-h: 90px;

// Rectangle Ad
$rectangle-w: 300px;
$rectangle-h: 250px;

// Halfpage Ad
$halfpage-w: 300px;
$halfpage-h: 600px;
$halfpage-h-m: 250px;

