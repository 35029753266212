.fp-vertical-article {
  @include padding(0 0 $spacer-v-s 0);
  @include margins(0 0 $spacer-v-xxxl 0);
  border-bottom: $border-dotted;
  
  // Remove dotted border for last two elements (desktop and mobile)
  .has-load-more &:nth-last-of-type(-n + 2) {
    border-bottom: unset;
    margin: unset;
  }

  &__desktop {
    @media(max-width: $mobile) {
      display: none;
    }
  }

  &__mobile {
    display: none;
    @media(max-width: $mobile) {
      display: flex;
    }

    .fp-vertical-article__content {
      flex-direction: column;
    }

    .fp-vertical-article__header {
      order: initial;
      padding: 0;
    }

    .fp-vertical-article__description {
      display: block;
    }

    .fp-vertical-article__details--mobile {
      display: none;
    }
  }

  a {
    @include flex(null, null, column);
  }

  &:first-child {
    padding-top: 0;
  }

  &__header {
    @include margins(0 0 $spacer-v-xs 0);
    @media(max-width: $mobile) {
      @include padding(0 $spacer-h-xxs);
      order: 2;
    }
  }

  &__catchline {
    @include font($text-m, $text-bold, normal);
    @include margins(0 0 $spacer-v-xxs 0);
    font-size: $text-m;
    line-height: $lh-m;
  }

  &__title {
    font-size: $text-xl;
    line-height: $lh-xl;

    &:hover {
      color: $color-primary;
    }

    @media(max-width: $tablet) {
      font-size: $text-l;
      line-height: 1.4;
    }
  }

  &__city {
    @include margins(0 $spacer-v-xxxs 0 0);
    color: $dark-gray;
    font-weight: bold;
  }

  &__content {
    @include flex();
  }

  &__image {
    @include size(19.375rem, 0);
    @include padding(0 0 33.33% 0);
    @include margins(0 $spacer-h-s 0 0);
    display: block;
    overflow: hidden;
    position: relative;

    img {
      @include size(100%, 100%);
      object-fit: cover;
      position: absolute;
    }

    @media(max-width: $tablet) {
      @include size(15.125rem, null);
      @include padding(0 0 22.22% 0);
    }

    @media(max-width: $mobile) {
      @include size(100%, null);
      @include padding(0 0 60% 0);
      @include margins(0 0 $spacer-h-xs 0);
    }

  }

  &__description {
    flex: 1;
    @media(max-width: $mobile) {
      display: none;
    }
  }

  &__excerpt {
    @include margins(0 0 $spacer-v-xxxs 0);
  }

  &__details {
    @include flex(null, center, null);

    &--mobile {
      @include padding(0 $spacer-h-xxs);
      display: none;
      order: 3;
      @media(max-width: $mobile) {
        @include flex(null, center, null);
      }
    }
  }

  &__author,
  &__date {
    @include font($text-s, $text-regular, normal);
    color: $dark-gray;
  }

  &__author {
    @include padding(0 $spacer-h-xxxs 0 0);
  }

  &__date {
    @include flex(null, null, row, wrap);
    @include padding(0 0 0 $spacer-h-xxxs);
    border-left: 1px solid $dark-gray;
    line-height: 1;
  }
}
