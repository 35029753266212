// This file contains the styles for the CONTAINERS
// It is imported in the _layout.scss file

.fp-container {
  @include size($container-width, null);
  @include margins(0 auto);

  @media screen and (min-width: $mobile) and (max-width: $tablet) {
    @include size($container-width-tablet, null);
  }

  @media (max-width: $mobile) {
    @include size(auto, null);
    @include margins(0 0.5rem);
  }

  &__small {
    @include size(83.3333333333%, null);
    @include margins(0 auto);
  }
}

.fp-col-1 {
  grid-column: span 1;
}

.fp-col-2 {
  grid-column: span 2;
}

.fp-col-3 {
  grid-column: span 3;
}

.fp-col-4 {
  grid-column: span 4;
}

.fp-col-5 {
  grid-column: span 5;
}

.fp-col-6 {
  grid-column: span 6;
}

.fp-col-7 {
  grid-column: span 7;
}

.fp-col-8 {
  grid-column: span 8;
}

.fp-col-9 {
  grid-column: span 9;
}

.fp-col-10 {
  grid-column: span 10;
}

.fp-col-11 {
  grid-column: span 11;
}

.fp-col-12 {
  grid-column: span 12;
}

.fp-col-1,
.fp-col-2,
.fp-col-3,
.fp-col-4,
.fp-col-5,
.fp-col-6,
.fp-col-7,
.fp-col-8,
.fp-col-9,
.fp-col-10,
.fp-col-11,
.fp-col-12 {
  max-width: 100%;
}
