.fp-related-articles {
  @include size(58.333333%, null);
  @include padding($spacer-v-xs 0);
  @include margins(0 0 $spacer-v-m 0);
  border-bottom: $border-solid;
  border-top: $border-solid;

  &__headline {
    @include font($text-m, $text-bold, normal);
    color: $text-color;
    text-transform: uppercase;
  }

  &__list {
    @include margins($spacer-v-xxxs 0 0 0);

    article {
      @include margins(0 0 $spacer-v-xxxs 0);
      display: block;

      &:last-child {
        @include margins(0);
      }
    }
  }

  &__catchline,
  &__title {
    @include font($text-m, $text-regular, normal, $lh: $lh-m);
    color: $color-primary;
  }

  &__title {
    color: $text-color;
    font-weight: $text-bold;
  }
}
