// This file contains all the ARTICLE HEADING styles
// This file is imported into the _article.scss file

.fp-article-heading {
  @include padding($spacer-v-m 0);

  &__special {
    @include font($text-s, $text-regular, normal, $lh: $lh-s);
    @include margins(0 auto calc($spacer-v-xxxs / 2) auto);
    color: #a39e99;
    flex: 0 0 auto;
  }

  &__catchline {
    @include font($text-xl, $text-bold, normal, $lh: $lh-xl);
    @include margins(0 0 $spacer-v-m 0);
    color: $text-color;
    @media(max-width: $mobile) {
      font-size: $text-article-catchline-m;
      line-height: $lh-article-catchline-m;
    }
  }

  &__title {
    @include font($text-xxxl, $text-bold, normal, $lh: $lh-xxxl);
    @include margins(0 0 $spacer-v-m 0);
    @media (max-width: $mobile) {
      font-size: $text-xxl;
      line-height: $lh-xxl;
    }
  }

  &__excerpt-wrapper {
    @include padding(0 0 0 0.938rem);
    @include margins(0 0 $spacer-v-m 0);
    border-left: 0.125rem solid $dark-gray;
  }

  &__city {
    @include font($text-l, $text-regular, normal, $lh: $lh-l);
    display: inline;

    &::after {
      content: ' - ';
    }
  }

  &__excerpt {
    @include font($text-l, $text-regular, normal, $lh: $lh-l);
    color: $text-color;
    display: inline;

    a {
      color: $color-primary;
    }

    &::after {
      content: ' ';
    }
  }

  &__author {
    @include font($text-l, $text-regular, normal, $lh: $lh-l);
    color: $dark-gray;
    display: inline;
  }

  &__author-list {
    margin-top: $spacer-v-m;

    ul > li {
      @include font($text-l, $text-regular, normal, $lh: $lh-l);
      color: $dark-gray;
      display: inline-block;

      img {
        border-radius: 50%;
        height: 50px;
        object-fit: cover;
        vertical-align: bottom;
        width: 50px;
      }

      .placeholder {
        background-color: $gray;
      }

      // anchor rules are substitute for 'text-underline-offset' rule
      // since it only has 93% caniuse score
      a {
        color: $dark-gray;
        position: relative;
        text-decoration: none;
        
        &:hover {
          color: $color-primary;
        }

        &:hover::after {
          border-color: $color-primary;
        }
      }

      a::after {
        border-color: $dark-gray;
        border-style: solid;
        border-width: 0 0 1px;
        bottom: -2px;
        content: '';
        left: 0;
        position: absolute;
        width: 100%;
      }

      // END text-underline-offset substitute
    }

    &__author-names-separator {
      margin-left: 10px;
    }
  }

  &__details {
    @include flex(null, center, null);
    flex-wrap: wrap;
  }

  &__date {
    @include flex(null, center, null);
    @include font($text-m, $text-regular, normal, $lh: $lh-m);
    color: $dark-gray;
    @media(max-width: $mobile) {
      align-items: flex-start;
      flex-direction: column;
    }

    &--date-updated-hidden {
      svg,
      .fp-article-heading__date-updated {
        display: none;
      }
    }

    * {
      display: inline;
      vertical-align: middle;
    }

    svg {
      height: 16px;
      width: 16px;
    }
  }

  &__date-published-legacy {
    display: none;
  }

  &__date-published {
    @include margins(0 $spacer-h-s 0 0);
    @media(max-width: $mobile) {
      @include margins(0 0 $spacer-v-xxxs 0);
    }
  }

  &__date-updated-container {
    @include flex(null, center, null);

    svg {
      @include margins(0 $spacer-h-xxxs 0 0);
    }
  }

  &__date-updated {
    @include flex(null, center, null);

    &::before {
      @include margins(0 $spacer-h-xxxs 0 0);
      content: 'aktualisiert:';
    }
  }

  .fp-paid-article__article {
    &::before {
      float: none;
    }
  }
}
