.paywall_overlay {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;
}

.fp-paywall-layer-overlay-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $mobile;

  .fp-article-paywall {
    &__dialog {
      background: $white;
      padding: 0;
    }

    &__header {
      border-bottom: 1px $dark-gray dotted;
      flex-direction: row;
      justify-content: start;
      padding: 0.7rem 1rem;

      .fp-article-paywall__logo {
        @include size(100px, 50px);
        background-size: contain;
        margin: 0 20px 0 0;
      }

      .fp-paywall-layer-overlay--closebutton {
        cursor: pointer;
        margin-left: auto;

        svg {
          fill: #c6c1ba;
        }
      }
    }

    &__header-description {
      text-align: left;
    }

    &__plan {
      display: flex;
      grid-gap: normal;
      padding: 0 1rem;

      .fp-article-paywall__plan--image {
        flex: 1;
        margin-bottom: auto;
        margin-top: auto;

        img {
          width: 100%;
        }
      }

      .fp-article-paywall__plan--body {
        align-items: start;
        flex: 3;
        flex-direction: column;
        margin-left: 10px;
      }

      .fp-article-paywall__plan--list {
        margin: 0 0 20px 20px;
      }

      .fp-article-paywall__plan--price {
        margin: 0 0 20px 20px;
      }

      .fp-button__primary {
        @include margins(0);
        align-items: center;
        display: flex;

        svg {
          height: 20px;
          margin: 0 0 0 4px;
          padding-top: 5px;
          width: 10px;
        }
      }
    }

    &__footer {
      background: $light-gray;
      border-top: 1px $dark-gray dotted;
      margin: 40px 0 0;
      padding: 0.7rem 0;

      span {
        margin-right: 5px;
      }

      a {
        cursor: pointer;
      }
    }
  }
}

.fp-paywall-layer-overlay_plus-logo .fp-article-paywall__logo {
  @include size(165px, $paywall-logo-height);
  background-image: url($paywall-plus-logo);
}

.container-grayed-background {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
}

.scrolling-disabled {
  overflow: hidden;
}

@media (max-width: $mobile) {
  .fp-paywall-layer-overlay-wrapper .fp-article-paywall__plan {
    .fp-article-paywall__plan--image {
      display: none;
    }

    .fp-article-paywall__plan--list {
      margin-left: 0;
    }

    .fp-article-paywall__plan--price {
      margin-left: 0;
    }

    .fp-button__primary {
      justify-content: center;
      width: 100%;
    }
  }
}
