.fp-media-annotations {
  @include flex();
  @include padding($spacer-v-s $spacer-h-s);
  bottom: 0;
  position: absolute;
  right: 0;
  z-index: 10;

  &__button {
    @include size($spacer-v-xxl, $spacer-v-xl);
    @include transition(0.15s, $expo);
    @include margins(0 $spacer-h-xxs 0 0);
    @include flex(center, center, null);
    background: $color-primary;
    border-radius: 0.1875rem;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background: $text-color;
    }
  }

  &__video {
    svg {
      @include size(0.7rem, auto);
    }
  }

  &__photo-series,
  &__info-series {
    svg {
      @include size(1.15rem, auto);
    }
  }
}


// Positioning only for the Aufmacher component
.fp-aufmacher {
  .fp-media-annotations {
    left: 0;
    top: 0;
  }
}
