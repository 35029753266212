.fp-most-read-articles {
  border-bottom: $border-l;
  margin-bottom: $spacer-v-xl;

  .fp-cta-link__title {
    border-bottom: 0;

    &:not([href]) {
      cursor: auto;
    }

    &:hover {
      border-bottom-color: unset;
    }
  }

  &__container {
    @include size(100%, null);
  }

  &__article {
    a {
      @media(max-width: $tablet) {
        @include flex();
      }
      @media(max-width: $mobile) {
        flex-direction: column;
      }
    }
  }

  &__image {
    @include size(100%, 0);
    @include padding(0 0 66.66% 0);
    @include margins(0 0 $spacer-v-s 0);
    display: block;
    overflow: hidden;
    position: relative;
    @media(max-width: $tablet) {
      display: none;
    }

    img {
      @include size(100%, 100%);
      object-fit: cover;
      position: absolute;
    }
  }

  &__content {
    @include flex(null, flex-start, null);
    @include padding($spacer-v-xxs 0);
    border-bottom: $border-dotted;
    flex: 1;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 0;
    }
  }

  &__header,
  &__link {
    flex: 1;
  }

  &__number {
    @include size(2.5rem, null);
    color: $gray;
    font-size: 3rem;
    line-height: 1;
  }

  &__list {
    @include padding(0 0 0 $spacer-h-xxs);
    @include margins(0 0 $spacer-v-xxs 0);
    border-left: $border-dotted;
  }

  &__catchline {
    @include font($text-m, $text-bold, normal);
    @include margins(0 0 calc($spacer-v-xxxs / 2) 0);
    color: $text-color;

    @media (max-width: $tablet) {
      font-size: $text-m;
      line-height: $lh-m;
    }
  }

  &__title {
    @include font($text-l, $text-bold, normal, $lh: $lh-l);

    @media (max-width: $mobile) {
      @include font($text-l, null, null);
    }

    &:hover {
      color: $color-primary;
    }
  }
}
