// This file contains all the BLOCKQUOTE styles
// This file is imported into the _article.scss file

.fp-blockquote {
  @include padding(0 0 $spacer-v-xs 0);
  @include margins(0 0 $spacer-v-m 0);
  @include font($text-xl, $text-regular, normal, $lh: $lh-xl);
  text-align: center;

  &__quotes {
    color: $color-primary;
    display: inline-block;
    font-style: italic;

    &:nth-of-type(1) {
      @include margins(0 calc($spacer * 2) 0 0);
    }

    &:nth-of-type(2) {
      @include margins(0 0 0 $spacer);
    }
  }

  &__text {
    @include font($text-xl, $text-regular, normal, $lh: $lh-xl);
    @include margins(0);
    @include padding(0);
    color: $dark-gray;
    display: inline;
    position: relative;

    &::before {
      @include font($text-xl, $text-regular, normal, $lh: $lh-xl);
      color: $dark-gray;
      display: inline;
      position: static;
    }
  }

  &__source {
    @include font($text-m, $text-regular, normal);
    @include margins(0);
    color: $dark-gray;
    display: inline-block;
    float: none;

    &::before {
      @include font($text-m, $text-regular, normal);
      display: inline;
    }
  }
}
