.fp-col-1,
.fp-col-2,
.fp-col-3,
.fp-col-4 .fp-newsletter__opt-in-modul {
  &__wrapper {
    border-bottom: 0;
    padding-top: 0;
  }

  &__info-wrapper {
    grid-column: span 12;
  }

  &__image {
    grid-column: span 12;
  }
    
  &__input {
    grid-column: span 12;
  }

  &__submit {
    grid-column: span 12;
  }

  &__title {
    @include margins(5px 0);
    font-size: 20px;
  }

  &__message {
    &-error,
    &-success {
      text-align: center;
    }
  }
}

@media (min-width: $mobile) and (max-width: $tablet) {
  .fp-col-1,
  .fp-col-2,
  .fp-col-3,
  .fp-col-4 .fp-newsletter__opt-in-modul {
    &__info-wrapper {
      grid-column: span 8;
    }
    
    &__image {
      grid-column: span 4;
    }
        
    &__input {
      grid-column: span 7;
    }
    
    &__submit {
      grid-column: span 5;
    }
  }
}

.fp-col-5,
.fp-col-6,
.fp-col-7 .fp-newsletter__opt-in-modul {
  &__input {
    grid-column: span 7;
  }

  &__submit {
    grid-column: span 5;
  }
    
  &__title {
    @include margins(5px 0);
    font-size: 20px;
  }
}

// Newsletter Opt-in-Modul in articles
.fp-article {
  @media (min-width: $tablet) {
    .fp-newsletter__opt-in-modul__input {
      grid-column: span 7;
    }

    .fp-newsletter__opt-in-modul__submit {
      grid-column: span 5;
    }
  }


  @media (min-width: $mobile) and (max-width: $tablet) {
    .fp-newsletter__opt-in-modul__input {
      grid-column: span 6;
    }

    .fp-newsletter__opt-in-modul__submit {
      grid-column: span 6;
    }
  }
}
