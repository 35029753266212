.fp-container-component {
  &.text__right {
    text-align: right;
  }

  &.text__center {
    text-align: center;
  }
}

